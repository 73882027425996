import { Avatar, Card, Col, Image, List, Row, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export default function ConfirmationPage({
  selectedCustomer,
  cart,
  setCurrentStep,
}) {
  return (
    <Content>
      <Row gutter={32}>
        <Col xs={24} sm={8}>
          <Card
            hoverable
            title="Customer Details"
            cover={
              <Image
                alt="example"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
              />
            }
            actions={[
              <AiFillEdit size={24} onClick={() => setCurrentStep(0)} />,
            ]}
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
              description={selectedCustomer.address}
            />
          </Card>
        </Col>

        <Col xs={24} sm={8}>
          <Card
            hoverable
            title="Order Details"
            actions={[
              <AiFillEdit size={24} onClick={() => setCurrentStep(1)} />,
            ]}
          >
            <List
              itemLayout="horizontal"
              dataSource={cart.map((product) => ({
                title: product.product.name,
                description: product.product.description,
                image: product.product.image,
                orderQty: product.orderQty,
              }))}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={
                      <Image
                        style={{
                          maxWidth: '120px',
                        }}
                        src={
                          item.image === ''
                            ? 'https://kadiwa-sais.xgitcorp.com/lpg-default-pic.png'
                            : item.image
                        }
                      />
                    }
                    title={
                      <Typography.Text strong>{item.title}</Typography.Text>
                    }
                    description={
                      <>
                        <Typography.Text strong>Description: </Typography.Text>
                        <Typography.Text>{item.description}</Typography.Text>
                        <br />
                        <Typography.Text strong>Order qty: </Typography.Text>
                        <Typography.Text>{item.orderQty} kg</Typography.Text>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
