import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Typography,
  message,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { IoIosRefresh } from 'react-icons/io';
import { GlobalContext } from '../../App';
import {
  deactivateUser,
  getUserById,
  getUsersByBranch,
  register,
} from '../../utils/api/UsersAPI';
import { UserContext } from '../../utils/context/UserContext';
import { AiOutlinePlus } from 'react-icons/ai';
import { getUserManagementTable } from '../../utils/tableData/Maintenance/UserManagementTable';
import {
  API_BOOLEAN_TRUE,
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { getUserMaintenanceFields } from '../../utils/data/UserFields';
import { FormInput } from '../../components';
import { getAccessLevels } from '../../utils/api/AccessLevelAPI';
import UserDefaultType from '../../utils/types/UserDefaultType';

export default function UserManagement() {
  const { authToken, openNotification } = useContext(GlobalContext);
  const { profile, formItemLayout } = useContext(UserContext);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentUserState, setCurrentUserState] = useState(UserDefaultType);
  const [localUsersListData, setLocalUsersListData] = useState([]);
  const [localAccessLevelsList, setLocalAccessLevelsList] = useState([]);

  const tableContents = localUsersListData
    .filter((data) => data.isDeleted !== API_BOOLEAN_TRUE)
    .filter((data) => data.id !== profile.id)
    .map((data, index) => ({ ...data, key: index }));

  const handleAction = async (id, action) => {
    setIsLoading(true);
    if (action === 'edit') {
      const res = await getUserById(profile.id, id, authToken);
      if (res.isSuccessful) {
        fetchAccessLevelsList();
        setCurrentUserState({ ...res.data });
        form.setFieldsValue(res.data);
        setIsModalOpen(true);
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'User management',
          getAPIResponseMessage(res.data)
        );
      }
    } else if (action === 'delete') {
      const res = await deactivateUser(
        profile.id,
        {
          userId: id,
          updatedBy: profile.id,
        },
        authToken
      );
      if (res.isSuccessful) {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'User management',
          getAPIResponseMessage(res.data)
        );
        fetchUsersList();
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'User management',
          getAPIResponseMessage(res.data)
        );
      }
    }
    setIsLoading(false);
  };

  const handleModalSave = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      if (currentUserState.id === '') {
        // New user flow
        const res = await register(profile.id, currentUserState, authToken);
        if (res.isSuccessful) {
          setIsModalOpen(false);
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'User management',
            getAPIResponseMessage(res.data)
          );
          fetchUsersList();
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'User management',
            getAPIResponseMessage(res.data)
          );
        }
      } else {
        // Edit user flow
        console.log(currentUserState);
      }
    } catch (error) {
      // No action needed.
    }
    setIsLoading(false);
  };

  const fetchUsersList = async () => {
    setIsLoading(true);
    const res = await getUsersByBranch(
      profile.id,
      profile.branchCode,
      authToken
    );
    if (res.isSuccessful) {
      setLocalUsersListData(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'User management',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const fetchAccessLevelsList = async () => {
    setIsLoading(true);
    const res = await getAccessLevels(profile.id, authToken);
    if (res.isSuccessful) {
      setLocalAccessLevelsList(
        res.data.map((level) => ({
          id: level.id,
          description: level.accessDescription,
        }))
      );
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'User management',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const resetData = () => {
    setCurrentUserState(UserDefaultType);
    form.setFieldsValue(UserDefaultType);
  };

  useEffect(() => {
    if (localUsersListData.length === 0) {
      fetchUsersList();
    }
  }, []);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      fetchUsersList();
    }, 1800000);

    return () => {
      clearInterval(fetchDataInterval);
    };
  }, []);

  return (
    <Content>
      <Row align={'middle'} className="mb-4">
        <Col xs={24} md={12}>
          <Typography.Title level={3}>Users Maintenance</Typography.Title>
        </Col>
        <Col xs={24} md={12} className="text-end">
          <Button
            type="primary"
            size="large"
            icon={<IoIosRefresh size={24} />}
            loading={isLoading}
            onClick={() => fetchUsersList()}
          />
          <Button
            type="primary"
            size="large"
            icon={<AiOutlinePlus size={24} />}
            loading={isLoading}
            className="ms-3"
            onClick={async () => {
              resetData();
              await fetchAccessLevelsList();
              setCurrentUserState({
                ...UserDefaultType,
                password: 'kadiwauser_01',
                updatedBy: profile.id,
                branchCode: profile.branchCode,
              });
              setIsModalOpen(true);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={getUserManagementTable(handleAction)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <Modal
        title="User Entry"
        centered
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleModalSave}
        okText={'Save'}
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="user_form"
          {...formItemLayout}
          initialValues={currentUserState}
        >
          {getUserMaintenanceFields(
            isLoading,
            currentUserState,
            setCurrentUserState,
            localAccessLevelsList
          ).map((data, index) => (
            <FormInput
              key={index}
              type={data.type}
              name={data.name}
              placeholder={data.placeholder}
              label={data.label}
              autoFocus={data.autofocus}
              value={data.value}
              onChange={data.setValue}
              required={data.required}
              disabled={data.disabled}
              dropdownOptions={data.dropdownOptions}
            />
          ))}
        </Form>
      </Modal>
    </Content>
  );
}
