import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React from 'react';

const styles = StyleSheet.create({
  receiptContainer: {
    width: 250, // Set the width of the receipt in points (1 inch = 72 points)
    padding: 10, // Add padding around the content
  },
  text: {
    fontSize: 12, // Set font size for the text
    marginBottom: 5, // Add margin between text lines
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 80,
    textAlign: 'right',
    color: 'grey',
  },
});

export default function OrderPrint({ orderNumber }) {
  return (
    <PDFViewer style={{ width: '95%', height: '50vh' }}>
      <Document>
        <Page size='A7' style={styles.page} orientation='landscape'>
          <View style={styles.receiptContainer}>
            <Text style={styles.text}>Order number:</Text>
            <Text
              style={{
                ...styles.text,
                fontSize: '28',
                textAlign: 'center',
                marginVertical: '20',
              }}
            >
              {orderNumber}
            </Text>
            {/* <Text style={styles.text}>{`${dayjs().format('YYYY-MM-DD')}`}</Text> */}
            {/* <Text style={styles.text}>Items:</Text>
            <Text style={styles.text}>- Item 1: $10.00</Text>
            <Text style={styles.text}>- Item 2: $15.50</Text>
            <Text style={styles.text}>Total: $25.50</Text> */}
            <Text style={styles.text}>Thank you for your purchase!</Text>
          </View>
          <Text style={styles.pageNumber} fixed>{`Timestamp: ${dayjs().format(
            'YYYY-MM-DD HH:mm:ss'
          )}`}</Text>
        </Page>
      </Document>
    </PDFViewer>
  );
}
