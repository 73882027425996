import {
  Avatar,
  Button,
  Card,
  Col,
  InputNumber,
  List,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import Meta from 'antd/es/card/Meta';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import OrderRetrievalModal from './OrderRetrievalModal';
import RetrievedOrderDefaultType from '../../utils/types/RetrievedOrderDefaultType';
import CheckerSelection from './CheckerSelection';
import ProductDefaultType from '../../utils/types/ProductDefaultType';
import UserDefaultType from '../../utils/types/UserDefaultType';
import { getBranch } from '../../utils/api/BranchesAPI';
import { UserContext } from '../../utils/context/UserContext';
import BranchDefaultType from '../../utils/types/BranchDefaultType';
import { postOrder } from '../../utils/api/TransactionAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import PaymentModal from './PaymentModal';
import { GlobalContext } from '../../App';

export default function SalesEntry() {
  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const [customerInfo, setCustomerInfo] = useState(UserDefaultType);
  const [checkerInfo, setCheckerInfo] = useState(UserDefaultType);

  const [selectedTransType, setSelectedTransType] = useState('');

  const [branchInfo, setBranchInfo] = useState(BranchDefaultType);
  const [orderInfo, setOrderInfo] = useState(RetrievedOrderDefaultType);
  const [tankInfo, setTankInfo] = useState(ProductDefaultType);

  const [isOrderRetrievalModalOpen, setIsOrderRetrievalModalOpen] =
    useState(false);

  const [isCheckerSelectionModalOpen, setIsCheckerSelectionModalOpen] =
    useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [isNewPriceModalOpen, setIsNewPriceModalOpen] = useState(false);

  const [temporaryNewPrice, setTemporaryNewPrice] = useState(0);
  const [selectedProductState, setSelectedProductState] = useState({
    id: '',
    productName: '',
    productDesc: '',
    productQty: '',
    pricePerkg: '',
    productPriceperkg: '',
  });

  const handleOnRetrieveOrder = (order, customerName, transType) => {
    const newOrderList = order.orderList.map((item, index) => {
      return { ...item, productPriceperkg: item.pricePerkg };
    });
    order = { ...order, orderList: newOrderList };
    setOrderInfo(order);
    setSelectedTransType(transType);
    setCustomerInfo({ ...customerInfo, firstName: customerName });
    setIsCheckerSelectionModalOpen(true);
  };

  const handleCheckerCompletion = (checker, tank) => {
    setCheckerInfo(checker);
    setTankInfo(tank);
    setIsCheckerSelectionModalOpen(false);
    setIsLoading(false);
  };

  const fetchBranchDetails = async () => {
    const res = await getBranch(profile.branchCode);
    if (res.isSuccessful) {
      setBranchInfo(res.data[0]);
    }
  };

  const getTotalOrderQty = () => {
    const sum = orderInfo.orderList.reduce((accumulator, currentRecord) => {
      return accumulator + parseFloat(currentRecord.productQty);
    }, 0);
    return sum;
  };

  const getTotalAmount = () => {
    const sum = orderInfo.orderList.reduce((accumulator, currentRecord) => {
      return (
        accumulator +
        parseFloat(currentRecord.productQty) *
          parseFloat(currentRecord.productPriceperkg)
      );
    }, 0);
    return sum.toFixed(2);
  };

  const handleSaveTransaction = async (tenderedAmount) => {
    setIsLoading(true);
    const payload = {
      orderNumber: orderInfo.orderNumber,
      cashier: profile.id,
      checker: checkerInfo.id,
      tankCode: tankInfo.id,
      transtype: selectedTransType,
      amountpaid: tenderedAmount,
      productList: orderInfo.orderList.map((product) => ({
        productId: product.id,
        totalQty: product.productQty,
        productDisc: 0,
        productPriceperkg: product.productPriceperkg,
      })),
    };
    const res = await postOrder(payload);
    if (res.isSuccess) {
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Sales entry',
        'Transaction posted successfully!'
      );

      resetData();
      setIsOrderRetrievalModalOpen(true);
      setIsLoading(true);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Sales entry',
        getAPIResponseMessage(res.data)
      );
      setIsLoading(false);
    }
  };

  const resetData = () => {
    setCheckerInfo(UserDefaultType);
    setBranchInfo(BranchDefaultType);
    setOrderInfo(RetrievedOrderDefaultType);
    setTankInfo(ProductDefaultType);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchBranchDetails();
    if (orderInfo.orderNumber === '') {
      setIsOrderRetrievalModalOpen(true);
    }
  }, []);

  return (
    <Content>
      <Row gutter={16}>
        <Col xs={24} sm={6}>
          <Card
            hoverable
            title="Customer Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={`${
                customerInfo.firstName === '' || customerInfo.firstName === ' '
                  ? 'Anonymous customer'
                  : customerInfo.firstName
              }`}
              // description={'customerInfo.address'}
            />
          </Card>
          <Card
            hoverable
            title="Branch Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta title={branchInfo.name} description={branchInfo.address} />
          </Card>
          <Card
            hoverable
            title="Cashier Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => console.log('Change cashier')}
              />,
            ]}
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={profile.username}
              description={`${profile.firstName} ${profile.lastName}`}
            />
          </Card>
          <Card
            hoverable
            title="Checker Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => console.log('Change checker')}
              />,
            ]}
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={checkerInfo.username}
              description={`${checkerInfo.firstName} ${checkerInfo.lastName}`}
            />
          </Card>
        </Col>
        <Col xs={24} sm={18}>
          <Card hoverable loading={isLoading} className="mb-3">
            <Meta
              title={`Total Items: ${orderInfo.orderList.length}`}
              className="mb-2"
            />
            <Meta
              title={`Total Order Qty: ${getTotalOrderQty()} kgs.`}
              className="mb-2"
            />
            <Meta
              title={`Total price: P${getTotalAmount()}`}
              className="mb-2"
            />
            <Meta
              title={`Transaction type: ${
                selectedTransType === '1' ? 'Retail' : 'Wholesale'
              }`}
              className="mb-2"
            />
          </Card>

          <Card loading={isLoading} hoverable title="Order Details">
            <List
              itemLayout="horizontal"
              dataSource={orderInfo.orderList.map((product) => ({
                id: product.id,
                title: product.productName,
                description: product.productDesc,
                price: product.pricePerkg,
                orderQty: product.productQty,
                productPriceperkg: product.productPriceperkg,
              }))}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <Tooltip title="Update price!">
                      <Button
                        type="primary"
                        size="large"
                        className="ms-2"
                        onClick={() => {
                          setSelectedProductState(item);
                          setIsNewPriceModalOpen(true);
                        }}
                      >
                        <AiFillEdit size={22} />
                      </Button>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Typography.Text strong>{item.title}</Typography.Text>
                    }
                    description={
                      <>
                        <Typography.Text strong>Description: </Typography.Text>
                        <Typography.Text>{item.description}</Typography.Text>
                        <br />
                        <Typography.Text strong>Order qty: </Typography.Text>
                        <Typography.Text>{item.orderQty} kg</Typography.Text>
                        <br />
                        <Typography.Text strong>Price Per Kg: </Typography.Text>
                        {item.price !== item.productPriceperkg && (
                          <>
                            <Typography.Text delete>
                              &#8369;{item.price}
                            </Typography.Text>
                            <Typography.Text type="success" className="ms-2">
                              &#8369;{item.productPriceperkg}
                            </Typography.Text>
                          </>
                        )}
                        {item.price === item.productPriceperkg && (
                          <Typography.Text>&#8369;{item.price}</Typography.Text>
                        )}
                        <br />
                        <br />
                        <Typography.Text strong>Total Price: </Typography.Text>
                        <Typography.Text>
                          &#8369;
                          {(item.orderQty * item.productPriceperkg).toFixed(2)}
                        </Typography.Text>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
          {!isLoading && (
            <Row justify="end" align="middle" className="my-3">
              <Button
                className="me-2"
                size="large"
                danger
                onClick={() => {
                  console.log('Void transaction!');
                }}
              >
                Void Transaction
              </Button>
              <Button
                className="me-2"
                size="large"
                type="primary"
                onClick={() => setIsPaymentModalOpen(true)}
              >
                Save Transaction
              </Button>
            </Row>
          )}
        </Col>
      </Row>
      <OrderRetrievalModal
        isOpen={isOrderRetrievalModalOpen}
        setIsOpen={setIsOrderRetrievalModalOpen}
        onSelectOrder={handleOnRetrieveOrder}
      />
      <CheckerSelection
        isOpen={isCheckerSelectionModalOpen}
        setIsOpen={setIsCheckerSelectionModalOpen}
        handleOnFinishSelection={handleCheckerCompletion}
      />

      <PaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        amountToPay={getTotalAmount()}
        handleOnSuccessPayment={handleSaveTransaction}
      />

      <Modal
        centered
        maskClosable={false}
        closable={false}
        title="Change price per KG?"
        open={isNewPriceModalOpen}
        onOk={() => {
          const updatedOrderList = orderInfo.orderList.map((item) => {
            if (item.id === selectedProductState.id) {
              return { ...item, productPriceperkg: temporaryNewPrice };
            } else {
              return item;
            }
          });
          setOrderInfo({ ...orderInfo, orderList: updatedOrderList });
          setIsNewPriceModalOpen(false);
          setTemporaryNewPrice(0);
        }}
        onCancel={() => setIsNewPriceModalOpen(false)}
        okText="Update price"
      >
        <Typography.Text strong>
          Please enter new amount for price per KG (PHP)
        </Typography.Text>
        <Space className="my-2">
          <InputNumber
            min={0}
            type="number"
            controls={false}
            value={parseFloat(selectedProductState.productPriceperkg)}
            style={{
              width: '100%',
            }}
            onChange={(value) => setTemporaryNewPrice(value)}
          />
        </Space>
      </Modal>
    </Content>
  );
}
