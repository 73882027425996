import { Button, Col, Empty, Popconfirm, Row, Steps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import TenderDeclaration from './TenderDeclaration';
import { UserContext } from '../../utils/context/UserContext';
import { processEODApi } from '../../utils/api/TransactionAPI';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_ConstantsAPI';
import EndOfDaySummaryDefaultType from '../../utils/types/EndOfDaySummaryDefaultType';
import EodSummary from './EodSummary';
import TenderDefaultType from '../../utils/types/TenderDefaultType';
import { EndOfDayPrint } from '../../components';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../utils/router/routes';

export default function EndOfDay() {
  const navigate = useNavigate();

  const { openNotification } = useContext(GlobalContext);
  const { profile, getCurrentDate } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const [eodSummary, setEodSummary] = useState(EndOfDaySummaryDefaultType);
  const [tenderDeclaration, setTenderDeclaration] = useState(TenderDefaultType);
  const [denominationTotal, setDenominationTotal] = useState('');

  const handleTenderFinish = (denomination, total) => {
    setTenderDeclaration(denomination);
    setDenominationTotal(total);
    setCurrentStep(currentStep + 1);
  };

  const handleOnCompleteEOD = (oprDetailsState, response) => {
    setEodSummary({
      ...eodSummary,
      newgt: response.newgt,
      newgtinv: response.newgtinv,
      oldgt: response.oldgt,
      oldinv: response.oldinv,
      oprR: oprDetailsState.opr_r,
      oprD: oprDetailsState.opr_d,
    });
    openNotification(
      SUCCESS_NOTIFICATION_TYPE,
      'End of day!',
      'Processing end of day report completed!'
    );
    setCurrentStep(currentStep + 1);
  };

  const steps = [
    {
      title: 'Tender Declaration',
      content: <TenderDeclaration onContinue={handleTenderFinish} />,
    },
    {
      title: 'End of day summary',
      content: (
        <EodSummary
          summary={eodSummary}
          denomination={tenderDeclaration}
          denominationTotal={denominationTotal}
          onCompleteEodProcess={handleOnCompleteEOD}
        />
      ),
    },
    {
      title: 'Print',
      content: (
        <EndOfDayPrint
          eodSummary={eodSummary}
          tenderDeclaration={tenderDeclaration}
          denominationTotal={denominationTotal}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const doInitialEodProcess = async () => {
    setIsLoading(true);

    const res = await processEODApi(getCurrentDate(), profile.branchCode);
    if (res.isSuccess) {
      setIsLoading(false);
      setEodSummary(res.summary);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'End of day process',
        'Something went wrong!'
      );
    }
  };

  useEffect(() => {
    doInitialEodProcess();
  }, []);

  return isLoading ? (
    <Empty
      description={<span>Loading...</span>}
      style={{ height: '100%' }}
    ></Empty>
  ) : (
    <Content>
      <Steps
        current={currentStep}
        items={items}
        className="mb-4"
        onChange={(value) => {
          if (value < currentStep) {
            setCurrentStep(value);
          }
        }}
      />
      {currentStep === 2 && (
        <Row className="mb-2">
          <Col xs={24} className="text-end">
            <Popconfirm
              title={
                'Please make sure you have printed the report. Confirm closing report?'
              }
              onConfirm={() => navigate(HOME_ROUTE)}
            >
              <Button type="primary">Close</Button>
            </Popconfirm>
          </Col>
        </Row>
      )}
      <Content>{steps[currentStep].content}</Content>
    </Content>
  );
}
