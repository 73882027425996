import { Button, Card, Col, Form, Input, Row, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import {
  getTenderDeclarationFirstColumnFields,
  getTenderDeclarationSecondColumnFields,
} from '../../utils/data/TenderDeclarationField';
import { ConfirmationModal, FormInput } from '../../components';
import TenderDefaultType from '../../utils/types/TenderDefaultType';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 20,
    },
  },
};

export default function TenderDeclaration({ onContinue }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [tenderDeclarationState, setTenderDeclarationState] =
    useState(TenderDefaultType);

  const [tenderTotal, setTenderTotal] = useState(null);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const calculateTotal = () => {
    const thousands = parseFloat(tenderDeclarationState.thousands) * 1000;
    const fiveHundreds = parseFloat(tenderDeclarationState.fiveHundreds) * 500;
    const twoHundreds = parseFloat(tenderDeclarationState.twoHundreds) * 200;
    const oneHundreds = parseFloat(tenderDeclarationState.oneHundreds) * 100;
    const fifties = parseFloat(tenderDeclarationState.fifties) * 50;
    const twenties = parseFloat(tenderDeclarationState.twenties) * 20;
    const tens = parseFloat(tenderDeclarationState.tens) * 10;
    const fives = parseFloat(tenderDeclarationState.fives) * 5;
    const peso = parseFloat(tenderDeclarationState.peso) * 1;
    const twentyFiveCents =
      parseFloat(tenderDeclarationState.twentyFiveCents) * 0.25;
    const fiveCents = parseFloat(tenderDeclarationState.fiveCents) * 0.05;
    const oneCent = parseFloat(tenderDeclarationState.oneCent) * 0.01;

    const total =
      thousands +
      fiveHundreds +
      twoHundreds +
      oneHundreds +
      fifties +
      twenties +
      tens +
      fives +
      peso +
      twentyFiveCents +
      fiveCents +
      oneCent;
    return total;
  };

  const handleContinue = async () => {
    try {
      await form.validateFields();
      onContinue(tenderDeclarationState, tenderTotal);
    } catch (error) {
      //
    }
  };

  return (
    <Content>
      <Card hoverable>
        <Row className="mb-3">
          <Col xs={24} sm={8}>
            <Space direction="horizontal">
              <Typography.Text strong>Total: </Typography.Text>
              <Input disabled={true} value={tenderTotal} />
            </Space>
          </Col>
        </Row>
        <Form
          form={form}
          name="tender_form"
          {...formItemLayout}
          initialValues={tenderDeclarationState}
          onFinish={() => setIsConfirmationModalOpen(true)}
          onFieldsChange={() => setTenderTotal(calculateTotal())}
        >
          <Row gutter={32}>
            <Col xs={24} sm={12}>
              {getTenderDeclarationFirstColumnFields(
                isLoading,
                tenderDeclarationState,
                setTenderDeclarationState,
                calculateTotal
              ).map((data, index) => (
                <FormInput
                  key={index}
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  dropdownOptions={data.dropdownOptions}
                />
              ))}
            </Col>
            <Col xs={24} sm={12}>
              {getTenderDeclarationSecondColumnFields(
                isLoading,
                tenderDeclarationState,
                setTenderDeclarationState
              ).map((data, index) => (
                <FormInput
                  key={index}
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  dropdownOptions={data.dropdownOptions}
                />
              ))}
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col xs={12}>
              <Button block type="primary" htmlType="submit">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body={<Typography.Text>Tendered amount: {tenderTotal}</Typography.Text>}
        onConfirm={handleContinue}
      />
    </Content>
  );
}
