export const getProductMaintenanceFields = (
  isLoading,
  productState,
  setProductState,
  categoryOptions
) => {
  return [
    {
      type: 'number',
      name: 'code',
      placeholder: 'Product code',
      label: 'Product code',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: productState.code,
      setValue: (e) => {
        const value = e.target.value;
        setProductState({ ...productState, code: value });
      },
    },
    {
      type: 'name',
      name: 'name',
      placeholder: 'Product name',
      label: 'Product name',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: productState.name,
      setValue: (e) => {
        const value = e.target.value;
        setProductState({ ...productState, name: value });
      },
    },
    {
      type: 'textarea',
      name: 'description',
      placeholder: 'Description',
      label: 'Description',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: productState.name,
      setValue: (e) => {
        const value = e.target.value;
        setProductState({ ...productState, description: value });
      },
    },
    {
      type: 'dropdown',
      name: 'category',
      placeholder: 'Product category',
      label: 'Product category',
      required: true,
      disabled: isLoading,
      value: productState.categoryId,
      dropdownOptions: categoryOptions,
      setValue: (value) => {
        const categoryDesc = categoryOptions.find((cat) => cat.id === value);
        setProductState({
          ...productState,
          categoryId: value,
          category: categoryDesc.description,
        });
      },
    },
    {
      type: 'quantity',
      name: 'quantity',
      placeholder: 'Product qty',
      label: 'Qty in KG',
      autofocus: false,
      required: productState.categoryId !== '3',
      disabled: isLoading,
      value: productState.quantity,
      setValue: (value) => {
        setProductState({ ...productState, quantity: value });
      },
    },
    {
      type: 'price',
      name: 'price',
      placeholder: 'Product price',
      label: 'Price in peso',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: productState.price,
      setValue: (e) => {
        const value = e.target.value;
        setProductState({ ...productState, price: value });
      },
    },
  ];
};
