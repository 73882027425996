import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Steps,
  Typography,
  message,
} from 'antd';
import {
  getOutletList,
  getOutletOrder,
  saveOutletOrder,
} from '../../utils/api/OutletAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import CustomerDefaultType from '../../utils/types/CustomerDefaultType';
import OutletDefaultType from '../../utils/types/OutletDefaultType';
import CustomerSelection from '../SalesOrder/CustomerSelection';
import ContainerSelection from '../SalesOrder/ContainerSelection';
import ConfirmationPage from '../SalesOrder/ConfirmationPage';
import { Content } from 'antd/es/layout/layout';
import { SuccessModal } from '../../components';
import { GlobalContext } from '../../App';

export default function OutletSalesOrder() {
  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [localOutletList, setLocalOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletDefaultType);
  const [cart, setCart] = useState([]);

  const [orderNumber, setOrderNumber] = useState(false);

  const [isConfirmOrderModalOpen, setIsConfirmOrderModalOpen] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const getOutlets = async () => {
    setIsLoading(true);
    const res = await getOutletList();
    if (res.isSuccessful) {
      setLocalOutletList(res.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Retail refill order',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const handleOnConfirmOrder = async () => {
    setIsLoading(true);
    const payload = {
      orderBranch: profile.branchCode,
      orderCustomer: selectedOutlet.id,
      productList: cart.map((order) => ({
        productId: order.product.id,
        productQty: order.orderQty,
      })),
    };
    const res = await saveOutletOrder(payload);
    if (res.isSuccessful) {
      setOrderNumber(res.orderNumber);
      setIsSuccessModalOpen(true);
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Retail refill order',
        'Order placed!'
      );
      setCart([]);
      setSelectedOutlet({ ...selectedOutlet, OutletDefaultType });
      setCurrentStep(0);
      setIsLoading(false);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Retail refill order',
        getAPIResponseMessage(res.data)
      );
    }
  };

  const steps = [
    {
      title: 'Outlet Selection',
      content: (
        <Row justify="center" align="middle" className="mb-3">
          <Col xs={24} sm={18}>
            <Select
              style={{ width: '100%' }}
              size="large"
              loading={isLoading}
              disabled={isLoading}
              showSearch
              placeholder="Search Outlet"
              onChange={(value) => {
                const outlet = localOutletList.find(
                  (user) => user.id === value
                );
                setSelectedOutlet(outlet);
                setCurrentStep(currentStep + 1);
              }}
              onSearch={(value) => console.log(value)}
              filterOption={(inputValue, option) => {
                const optionDescription = option.children
                  .join(' ')
                  .toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
            >
              {localOutletList.map((record, index) => (
                <Select.Option key={index} value={record.id}>
                  {record.outlet_desc}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Container Details',
      content: <ContainerSelection cart={cart} setCart={setCart} />,
    },
    {
      title: 'Confirmation',
      content: (
        <ConfirmationPage
          selectedCustomer={{
            firstName: selectedOutlet.outlet_desc,
            lastName: '',
          }}
          cart={cart}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    getOutlets();
  }, []);

  return (
    <Content>
      <Steps
        current={currentStep}
        items={items}
        className="mb-4"
        onChange={(value) => {
          if (value < currentStep) {
            setCurrentStep(value);
          }
        }}
      />
      <Row>
        <Col xs={8}>
          {currentStep >= 1 && (
            <Button
              className="mb-4"
              type="primary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Prev
            </Button>
          )}
        </Col>
        <Col xs={8} className="text-center">
          {currentStep === 2 && (
            <Button
              className="mb-4"
              type="primary"
              size="large"
              onClick={() => setIsConfirmOrderModalOpen(true)}
            >
              Confirm order!
            </Button>
          )}
        </Col>
        <Col xs={8} className="text-end">
          {cart.length > 0 && currentStep === 1 && (
            <Button
              className="mb-4"
              type="primary"
              style={{ whiteSpace: 'normal', height: 'auto' }}
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Proceed to checkout
            </Button>
          )}
        </Col>
      </Row>

      <Content>{steps[currentStep].content}</Content>
      <Modal
        centered
        closable={false}
        open={isConfirmOrderModalOpen}
        title="Kindly confirm your order."
        onOk={() => {
          handleOnConfirmOrder();
          setIsConfirmOrderModalOpen(false);
        }}
        onCancel={() => setIsConfirmOrderModalOpen(false)}
        okText="Confirm"
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        orderNumber={orderNumber}
      />
    </Content>
  );
}
