import axios from 'axios';
import { ACCESS_LEVEL_ENDPOINT, API_CALL_ERROR } from './_ConstantsAPI';

export async function getAccessLevels(requestorId, authToken) {
  try {
    const { data } = await axios.get(ACCESS_LEVEL_ENDPOINT, {
      params: {
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
