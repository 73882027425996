import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns-tz';
import { useEffect, useState } from 'react';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  container: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  column: {
    flexGrow: 1,
    marginRight: 10,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  fieldName: {
    color: 'grey',
    fontSize: 8,
  },
  fieldValue: {
    fontSize: 10,
    color: 'black',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '33.33%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    padding: 5,
    fontSize: '6px',
  },
});

export default function CheckerHistoryPrint({
  type,
  records,
  date,
  profile,
  selectedChecker,
}) {
  const [totalQty, setTotalQty] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const getTotalQty = () => {
    const sum = records
      .map((record) => parseFloat(record.salesQty))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setTotalQty(sum.toFixed(2));
  };

  const getType = () => {
    switch (type) {
      case '1':
        return 'Cash';
      case '2':
        return 'Dealer';
      case '3':
        return 'RPR';
      default:
        return 'Cash';
    }
  };

  useEffect(() => {
    if (records.length !== 0) {
      setTotalTransactions(records.length);
      getTotalQty();
    }
  }, [records]);

  return (
    <Document>
      <Page size={'LETTER'} style={styles.body} orientation="landscape">
        <Text style={styles.header} fixed>
          K-GAZ {getType()}
        </Text>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Prepared By:{' '}
              <Text
                style={styles.fieldValue}
              >{`${profile.firstName} ${profile.lastName}`}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Checker:{' '}
              <Text
                style={styles.fieldValue}
              >{`${selectedChecker.firstName} ${selectedChecker.lastName}`}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Date: <Text style={styles.fieldValue}>{date}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Print timestamp:{' '}
              <Text style={styles.fieldValue}>
                {format(new Date(), 'yyyy-MM-dd HH:mm:ss', {
                  timeZone: 'Asia/Manila',
                })}
              </Text>
            </Text>
          </View>
        </View>
        <View style={{ ...styles.container, marginBottom: 20 }}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Total QTY: <Text style={styles.fieldValue}>{totalQty}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Total Transactions:{' '}
              <Text style={styles.fieldValue}>{totalTransactions}</Text>
            </Text>
          </View>
        </View>
        {records.map((record, index) => (
          <View key={index} style={{ marginBottom: 15 }}>
            <View style={{ ...styles.container, marginBottom: 5 }}>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Customer:{' '}
                  <Text style={styles.fieldValue}>{record.customer_name}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Date: <Text style={styles.fieldValue}>{record.date}</Text>
                </Text>
              </View>
            </View>
            <View style={{ ...styles.container, marginBottom: 5 }}>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Total QTY:{' '}
                  <Text style={styles.fieldValue}>{record.salesQty}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Total amount:{' '}
                  <Text style={styles.fieldValue}>{record.total_amount}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Amount paid:{' '}
                  <Text style={styles.fieldValue}>{record.amount_paid}</Text>
                </Text>
              </View>
            </View>
            <View style={{ ...styles.container, marginBottom: 5 }}>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Type:{' '}
                  <Text style={styles.fieldValue}>{record.transtype}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Price per KG:{' '}
                  <Text style={styles.fieldValue}>{record.price_per_kg}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Order no.:{' '}
                  <Text style={styles.fieldValue}>{record.orderNumber}</Text>
                </Text>
              </View>
            </View>
            <View style={{ ...styles.container, marginBottom: 5 }}>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Total amount:{' '}
                  <Text style={styles.fieldValue}>{record.total_amount}</Text>
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.fieldName}>
                  Amount paid:{' '}
                  <Text style={styles.fieldValue}>{record.amount_paid}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Product</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Description</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Qty</Text>
                </View>
              </View>
              {record?.orderList?.map((order, index) => (
                <View key={`${index}-a-${index}`} style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>{order.productName}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{order.productDesc}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{order.salesQty}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
