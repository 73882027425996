import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Result, Typography } from 'antd';
import React, { useState } from 'react';

export default function SuccessModal({ isOpen, setIsOpen, orderNumber }) {
  return (
    <Modal
      centered
      open={isOpen}
      footer={null}
      destroyOnClose
      onCancel={() => setIsOpen(false)}
    >
      {/* <div className='print-content'>
        <Typography.Text>Order # {orderNumber}</Typography.Text>
      </div> */}
      <Result
        className='p-2 print-content'
        status='success'
        title={orderNumber}
        subTitle={`Order successfully saved!`}
        // extra={[
        //   <Button key='close' onClick={() => setIsOpen(false)}>
        //     Close
        //   </Button>,
        // ]}
      />
      <Button
        className='me-2'
        onClick={() => {
          setIsOpen(false);
        }}
      >
        Close
      </Button>
      <Button
        onClick={() => {
          const printContent =
            document.querySelector('.print-content').innerHTML;
          const originalContent = document.body.innerHTML;
          document.body.innerHTML = printContent;
          window.print();
          // document.body.innerHTML = originalContent;
          // window.location.reload();
        }}
      >
        Print
      </Button>
    </Modal>
  );
}
