import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_CHECKER_HISTORY_ENDPOINT,
  GET_CUSTOMER_HISTORY_ENDPOINT,
  GET_DAILY_STOCKS_REPORT_ENDPOINT,
  GET_SALES_HISTORY_ENDPOINT,
} from './_ConstantsAPI';

export async function getSalesHistory(
  branch,
  dateFrom,
  dateTo,
  category,
  outletId
) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const { data } = await axios.get(
      `${GET_SALES_HISTORY_ENDPOINT}/${branch}/${dateFrom}/${dateTo}/${category}/${outletId}`,
      {
        headers,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getDailyStocksReportAPI(date, branch) {
  try {
    const { data } = await axios.get(
      `${GET_DAILY_STOCKS_REPORT_ENDPOINT}/${date}/${date}/${branch}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCheckerHistoryReportAPI(
  dateFrom,
  dateTo,
  branch,
  checkerId
) {
  try {
    const { data } = await axios.get(
      `${GET_CHECKER_HISTORY_ENDPOINT}/${dateFrom}/${dateTo}/${branch}/${checkerId}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCustomerHistoryReportAPI(
  customerId,
  dateFrom,
  dateTo
) {
  try {
    const { data } = await axios.get(
      `${GET_CUSTOMER_HISTORY_ENDPOINT}/${customerId}/${dateFrom}/${dateTo}`
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
