import { Button, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export function getUserManagementTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Access Level',
      dataIndex: 'accessDescription',
      key: 'accessDescription',
      sorter: (a, b) => a.accessDescription.localeCompare(b.accessDescription),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id, 'edit')}
            icon={<AiFillEdit size={20} />}
            className="mx-2"
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure to delete ${record.firstName} ${record.lastName}?`}
            onConfirm={() => handleAction(record.id, 'delete')}
          >
            <Button type="primary" danger icon={<AiFillDelete size={20} />}>
              Deactivate
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
}
