import './App.css';

//fonts
import './assets/fonts/Poppins/Poppins-Light.ttf';
import './assets/fonts/Poppins/Poppins-Thin.ttf';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import './assets/fonts/Poppins/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins/Poppins-Medium.ttf';
import './assets/fonts/Poppins/Poppins-Bold.ttf';
import React, { createContext, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { UserProvider } from './utils/context/UserContext';
import AuthRoute from './utils/router/AuthRoute';
import ActualRoute from './utils/router/ActualRoute';
import { notification } from 'antd';
import CryptoJS from 'crypto-js';

function App() {
  const [api, contextHolder] = notification.useNotification();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState('');

  const openNotification = (type, messageHeader, messageDescription) => {
    api[type]({
      message: messageHeader,
      description: messageDescription,
    });
  };

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, 'kadiwaencryptorkey').toString();
  };

  const decryptValue = (value) => {
    return CryptoJS.AES.decrypt(value, 'kadiwaencryptorkey').toString(
      CryptoJS.enc.Utf8
    );
  };

  const globalContext = {
    isLoggedIn,
    setIsLoggedIn,
    authToken,
    setAuthToken,
    openNotification,
    encryptValue,
    decryptValue,
  };
  return (
    <GlobalContext.Provider value={globalContext}>
      <UserProvider>
        <HashRouter>
          {contextHolder}
          <div className="App">
            {isLoggedIn ? <ActualRoute /> : <AuthRoute />}
          </div>
        </HashRouter>
      </UserProvider>
    </GlobalContext.Provider>
  );
}

export default App;

export const GlobalContext = createContext();
