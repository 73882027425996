import { Modal } from 'antd';
import React from 'react';

export default function CustomModal({
  title = '',
  okText = 'Confirm',
  cancelText = 'Cancel',
  isClosable = false,
  isLoading,
  isOpen,
  setIsOpen,
  onOk,
  onCancel = () => setIsOpen(false),
  props,
  children,
}) {
  return (
    <Modal
      title={title}
      centered
      closable={isClosable}
      maskClosable={isClosable}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText={okText}
      cancelText={cancelText}
      confirmLoading={isLoading}
      {...props}
    >
      {children}
    </Modal>
  );
}
