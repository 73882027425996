export default {
  date: null,

  oprRetailSales: null,
  oprRetailKg: null,

  oprDealerSales: null,
  oprDealerKg: null,

  retailrefill: null,

  totalSales: null,
  totalKg: null,

  newgt: null,
  oldgt: null,
  newinventory: null,
  oldinventory: null,
};
