export const getChangePasswordFields = (
  isLoading,
  passwordState,
  setPasswordState
) => {
  return [
    {
      type: 'password',
      name: 'oldPassword',
      placeholder: 'Your current password!',
      label: 'Current password',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: passwordState.oldPassword,
      setValue: (e) => {
        const value = e.target.value;
        setPasswordState({ ...passwordState, oldPassword: value });
      },
    },
    {
      type: 'password',
      name: 'newPassword',
      placeholder: 'Your New password!',
      label: 'New password',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: passwordState.newPassword,
      setValue: (e) => {
        const value = e.target.value;
        setPasswordState({ ...passwordState, newPassword: value });
      },
    },
    {
      type: 'password',
      name: 'confirmPassword',
      placeholder: 'Confirm password!',
      label: 'Confirm password',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: passwordState.confirmPassword,
      setValue: (e) => {
        const value = e.target.value;
        setPasswordState({ ...passwordState, confirmPassword: value });
      },
    },
  ];
};
