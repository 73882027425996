import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Image,
  InputNumber,
  List,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from 'antd';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { getUsersByBranch } from '../../utils/api/UsersAPI';
import { GlobalContext } from '../../App';
import {
  getSolidAssetsProducts,
  postSolidAssetTransaction,
} from '../../utils/api/SolidAssetsAPI';
import { Content } from 'antd/es/layout/layout';
import Meta from 'antd/es/card/Meta';
import { getBranch } from '../../utils/api/BranchesAPI';
import CustomerDefaultType from '../../utils/types/CustomerDefaultType';
import UserDefaultType from '../../utils/types/UserDefaultType';
import ProductDefaultType from '../../utils/types/ProductDefaultType';
import { AiFillEdit } from 'react-icons/ai';
import CustomerSelection from '../SalesOrder/CustomerSelection';
import BranchDefaultType from '../../utils/types/BranchDefaultType';
import { MdOutlineAddCircle } from 'react-icons/md';
import PaymentModal from '../SalesEntry/PaymentModal';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';

export default function SolidAssetsSales() {
  const { profile } = useContext(UserContext);
  const { authToken, openNotification } = useContext(GlobalContext);

  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [productsList, setProductsList] = useState([]);
  const [checkersList, setCheckersList] = useState([]);

  const [branch, setBranch] = useState(BranchDefaultType);

  const [ordersInfo, setOrdersInfo] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(CustomerDefaultType);
  const [selectedProduct, setSelectedProduct] = useState(ProductDefaultType);
  const [selectedChecker, setSelectedChecker] = useState(UserDefaultType);

  const [temporaryNewPrice, setTemporaryNewPrice] = useState(0);

  // MODALS
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isCheckerModalOpen, setIsCheckerModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isNewPriceModalOpen, setIsNewPriceModalOpen] = useState(false);

  const getTotalAmount = () => {
    const sum = ordersInfo.reduce((accumulator, currentRecord) => {
      return accumulator + parseFloat(currentRecord.newPrice);
    }, 0);
    return sum.toFixed(2);
  };

  const handlePostTransaction = async (tenderedAmount) => {
    const orderList = ordersInfo.map((order) => ({
      productId: order.id,
      totalQty: '1',
      price: order.newPrice,
    }));
    const payload = {
      cashier: profile.id,
      customer: selectedCustomer.id,
      checker: selectedChecker.id,
      orderBranch: profile.branchCode,
      amountpaid: tenderedAmount,
      productList: orderList,
    };
    const res = await postSolidAssetTransaction(payload);
    if (res.isSuccess) {
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Solid Asset Sales',
        getAPIResponseMessage(res.message)
      );
      resetData();
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Solid Asset Sales',
        getAPIResponseMessage(res.message)
      );
    }
  };

  const resetData = () => {
    setSelectedChecker(UserDefaultType);
    setSelectedCustomer(CustomerDefaultType);
    setSelectedProduct(ProductDefaultType);
    setOrdersInfo([]);
    loadInitialData();
  };

  const loadInitialData = async () => {
    setIsInitialLoading(true);
    setIsLoading(true);
    await Promise.all([
      getBranch(profile.branchCode),
      getUsersByBranch(profile.id, profile.branchCode, authToken),
      getSolidAssetsProducts(),
    ])
      .then((response) => {
        if (response[0].isSuccessful) {
          setBranch(response[0].data[0]);
        }
        if (response[1].isSuccessful) {
          setCheckersList(
            response[1].data.filter((record) => record.accessLevel === '4')
          );
        }
        if (response[2].isSuccessful) {
          const products = response[2].data.map((record) => ({
            ...record,
            newPrice: record.price,
          }));
          setProductsList(products);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setIsLoading(false);
    setIsInitialLoading(false);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return isInitialLoading ? (
    <Row justify="center" align="middle">
      <Spin size="large" />
    </Row>
  ) : (
    <Content>
      <Row gutter={16}>
        <Col xs={24} sm={6}>
          <Card
            hoverable
            title="Customer Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => setIsCustomerModalOpen(true)}
              />,
            ]}
          >
            <Badge dot={selectedCustomer.id === ''}>
              <Meta
                avatar={
                  <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
                }
                title={`${
                  selectedCustomer.id !== '' &&
                  (selectedCustomer.firstName === '' ||
                    selectedCustomer.firstName === ' ')
                    ? 'Anonymous customer'
                    : selectedCustomer.firstName
                }`}
                description={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
              />
            </Badge>
          </Card>
          <Card
            hoverable
            title="Checker Info"
            loading={isLoading}
            className="mb-3"
            actions={[
              <AiFillEdit
                size={24}
                onClick={() => setIsCheckerModalOpen(true)}
              />,
            ]}
          >
            <Badge dot={selectedChecker.id === ''}>
              <Meta
                avatar={
                  <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
                }
                title={selectedChecker.username}
                description={`${selectedChecker.firstName} ${selectedChecker.lastName}`}
              />
            </Badge>
          </Card>
          <Card
            hoverable
            title="Branch Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta title={branch.name} description={branch.address} />
          </Card>
          <Card
            hoverable
            title="Cashier Info"
            loading={isLoading}
            className="mb-3"
          >
            <Meta
              avatar={
                <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              title={profile.username}
              description={`${profile.firstName} ${profile.lastName}`}
            />
          </Card>
        </Col>
        <Col xs={24} sm={18}>
          <Card hoverable loading={isLoading} className="mb-3">
            <Meta
              title={`Total Items: ${ordersInfo.length}`}
              className="mb-2"
            />
            <Meta
              title={`Total price: P${getTotalAmount()}`}
              className="mb-2"
            />
            <Meta title={'Type: Solid Asset Sales'} className="mb-2" />
          </Card>

          <Card
            loading={isLoading}
            hoverable
            title="Order Details"
            extra={
              <>
                <Tooltip title="Add Item">
                  <Button
                    type="primary"
                    size="large"
                    className="ms-2"
                    onClick={() => setIsProductModalOpen(true)}
                  >
                    <MdOutlineAddCircle size={22} />
                  </Button>
                </Tooltip>
              </>
            }
          >
            <List
              itemLayout="horizontal"
              dataSource={ordersInfo.map((product) => ({
                id: product.id,
                title: product.name,
                description: product.description,
                price: product.price,
                newPrice: product.newPrice,
              }))}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <Tooltip title="Update price!">
                      <Button
                        type="primary"
                        size="large"
                        className="ms-2"
                        onClick={() => {
                          setSelectedProduct(item);
                          setIsNewPriceModalOpen(true);
                        }}
                      >
                        <AiFillEdit size={22} />
                      </Button>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Typography.Text strong>
                        Name: {item.title}
                      </Typography.Text>
                    }
                    description={
                      <>
                        <Typography.Text strong>Description: </Typography.Text>
                        <Typography.Text>{item.description}</Typography.Text>
                        <br />
                        <Typography.Text strong>Price Per Kg: </Typography.Text>
                        {parseFloat(item.price) !==
                          parseFloat(item.newPrice) && (
                          <>
                            <Typography.Text delete>
                              &#8369;{item.price}
                            </Typography.Text>
                            <Typography.Text type="success" className="ms-2">
                              &#8369;{item.newPrice}
                            </Typography.Text>
                          </>
                        )}
                        {parseFloat(item.price) ===
                          parseFloat(item.newPrice) && (
                          <Typography.Text>&#8369;{item.price}</Typography.Text>
                        )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
          {!isLoading && (
            <Row justify="end" align="middle" className="my-3">
              <Button
                size="large"
                type="primary"
                disabled={
                  selectedChecker.id === '' ||
                  selectedCustomer.id === '' ||
                  ordersInfo.length <= 0
                }
                onClick={() => setIsPaymentModalOpen(true)}
              >
                Save Transaction
              </Button>
            </Row>
          )}
        </Col>
      </Row>
      <Modal
        centered
        open={isCustomerModalOpen}
        footer={null}
        width={'90vw'}
        onCancel={() => setIsCustomerModalOpen(false)}
      >
        <CustomerSelection
          onSelectCustomer={(customer) => {
            setSelectedCustomer(customer);
            setIsCustomerModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        centered
        keyboard={false}
        closable={false}
        maskClosable={false}
        open={isCheckerModalOpen}
        footer={null}
        onCancel={() => setIsCheckerModalOpen(false)}
      >
        <Row justify="center" align="middle" className="mb-3">
          <Col span={6} className="text-end">
            <Typography.Text strong className="me-2">
              Checker:
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Select
              style={{ width: '100%' }}
              size="large"
              showSearch
              placeholder="Search checker"
              onChange={(value) => {
                const checker = checkersList.find((user) => user.id === value);
                setSelectedChecker(checker);
              }}
              onSearch={(value) => console.log(value)}
              filterOption={(inputValue, option) => {
                const optionDescription = option.children
                  .join(' ')
                  .toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
            >
              {checkersList.map((record, index) => (
                <Select.Option key={index} value={record.id}>
                  {record.firstName} {record.lastName}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="mb-3">
          <Button
            size="large"
            type="primary"
            onClick={() => setIsCheckerModalOpen(false)}
          >
            Complete
          </Button>
        </Row>
      </Modal>
      <Modal
        centered
        open={isProductModalOpen}
        footer={null}
        width={'90vw'}
        onCancel={() => setIsProductModalOpen(false)}
      >
        <Row className="mt-4" gutter={[32, 32]}>
          {productsList
            .filter((data) => data.isTank !== '1')
            .filter((data) => data.categoryId === '3')
            .map((product, index) => (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <Card
                  hoverable
                  cover={
                    <img
                      alt="product"
                      src={
                        product.image === ''
                          ? 'https://kadiwa-sais.xgitcorp.com/lpg-default-pic.png'
                          : product.image
                      }
                    />
                  }
                  onClick={() => {
                    setOrdersInfo((prevState) => [...prevState, product]);
                    setIsProductModalOpen(false);
                  }}
                >
                  <Meta title={product.name} className="mb-2" />
                  <Meta
                    description={`Description: ${product.description}`}
                    className="mb-2"
                  />
                  <Meta
                    description={`Category: ${product.category}`}
                    className="mb-2"
                  />
                  <Meta
                    description={`KG Capacity: ${product.price}`}
                    className="mb-2"
                  />
                </Card>
              </Col>
            ))}
        </Row>
      </Modal>
      <Modal
        centered
        maskClosable={false}
        closable={false}
        title="Change price per KG?"
        open={isNewPriceModalOpen}
        onOk={() => {
          const updatedOrderList = ordersInfo.map((item) => {
            if (item.id === selectedProduct.id) {
              return { ...item, newPrice: temporaryNewPrice };
            } else {
              return item;
            }
          });
          setOrdersInfo(updatedOrderList);
          setIsNewPriceModalOpen(false);
          setTemporaryNewPrice(0);
        }}
        onCancel={() => setIsNewPriceModalOpen(false)}
        okText="Update price"
      >
        <Typography.Text strong>Please enter new amount: </Typography.Text>
        <Space className="my-2">
          <InputNumber
            min={0}
            type="number"
            controls={false}
            value={parseFloat(selectedProduct.price)}
            style={{
              width: '100%',
            }}
            onChange={(value) => setTemporaryNewPrice(value)}
          />
        </Space>
      </Modal>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        amountToPay={getTotalAmount()}
        handleOnSuccessPayment={handlePostTransaction}
      />
    </Content>
  );
}
