import { Button, Card, Col, Form, Modal, Row, Table, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import {
  getOutletExpenseAPI,
  getOutletExpenseCategories,
  postOutletExpenseRequest,
  postReturnOutletExpenseAPI,
} from '../../utils/api/OutletAPI';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { getExpenseRequestFields } from '../../utils/data/ExpenseField';
import { FormInput } from '../../components';
import ExpenseRequestDefaultType from '../../utils/types/ExpenseRequestDefaultType';
import { getOutletExpenseTableHeader } from '../../utils/tableData/Outlet/OutletExpenseHeader';
import { IoIosRefresh } from 'react-icons/io';

export default function OutletExpenseRequest() {
  const { openNotification } = useContext(GlobalContext);
  const { profile, formItemLayout, getCurrentDate } = useContext(UserContext);

  const [form] = Form.useForm();

  const [form_return] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [currentRequestState, setCurrentRequestState] = useState(
    ExpenseRequestDefaultType
  );

  const [localCategoryList, setLocalCategoryList] = useState([]);
  const [localExpenseList, setLocalExpenseList] = useState([]);

  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [selectedExpenseState, setSelectedExpenseState] = useState({
    ...ExpenseRequestDefaultType,
    return_amount: null,
  });

  const tableContents = localExpenseList.map((data, index) => ({
    ...data,
    key: index,
  }));

  const handleReturnExpenseSave = async () => {
    setIsRequesting(true);
    try {
      await form_return.validateFields();
      const res = await postReturnOutletExpenseAPI(selectedExpenseState);
      if (res.isSuccess) {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Return expense',
          'Success updating expense!'
        );
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Return expense',
          'Something went wrong!'
        );
      }
    } catch (error) {}
    setIsRequesting(false);
  };

  const handleTableAction = (id) => {
    const rec = localExpenseList.find((record) => record.id === id);
    setSelectedExpenseState(rec);
    setIsReturnModalOpen(true);
  };

  const handleSaveRequest = async () => {
    setIsRequesting(true);
    try {
      await form.validateFields();
      const res = await postOutletExpenseRequest(currentRequestState);
      if (res.isSuccess) {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Expense request',
          getAPIResponseMessage(res.message)
        );
        await doInit();
        resetData();
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Expense request',
          getAPIResponseMessage(res.message)
        );
      }
    } catch (error) {
      //
    }
    setIsRequesting(false);
  };

  const resetData = () => {
    setCurrentRequestState(ExpenseRequestDefaultType);
    form.resetFields();
    form.setFieldsValue(ExpenseRequestDefaultType);
  };

  const fetchOutletExpenseList = async () => {
    const res = await getOutletExpenseAPI();
    if (res.isSuccess) {
      setLocalExpenseList(res.expense);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Expense List',
        'Something went wrong!'
      );
    }
  };

  const doInit = async () => {
    setIsLoading(true);
    const res = await getOutletExpenseCategories();
    if (res.isSuccess) {
      const categories = res.categoryList?.map((record) => ({
        id: record.id,
        description: record.categoryDesc,
      }));
      setLocalCategoryList(categories);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Expense Categories',
        'Something went wrong!'
      );
    }
    fetchOutletExpenseList();
    setCurrentRequestState({
      ...currentRequestState,
      date: getCurrentDate(),
      addedby: profile.id,
      branch: profile.branchCode,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    doInit();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Expense request</Typography.Title>
      <Row justify="center" align="middle" gutter={16}>
        <Col xs={24} sm={12}>
          <Card hoverable bordered={false} loading={isLoading}>
            <Form
              form={form}
              name="expense_form"
              {...formItemLayout}
              initialValues={currentRequestState}
              onFinish={handleSaveRequest}
            >
              {getExpenseRequestFields(
                isRequesting,
                currentRequestState,
                setCurrentRequestState,
                localCategoryList
              ).map((data, index) => (
                <FormInput
                  key={index}
                  type={data.type}
                  name={data.name}
                  placeholder={data.placeholder}
                  label={data.label}
                  autoFocus={data.autofocus}
                  value={data.value}
                  onChange={data.setValue}
                  required={data.required}
                  disabled={data.disabled}
                  dropdownOptions={data.dropdownOptions}
                />
              ))}
              <FormInput
                type={'text'}
                name={'date'}
                label={'Date'}
                autoFocus={false}
                placeholder={getCurrentDate()}
                value={getCurrentDate()}
                required={false}
                disabled={true}
              />
              <Row justify="center" align="middle">
                <Col xs={12}>
                  <Button
                    block
                    htmlType="submit"
                    type="primary"
                    disabled={isRequesting}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card
            hoverable
            bordered
            title="Expenses today"
            extra={
              <Button
                type="primary"
                size="large"
                icon={<IoIosRefresh size={24} />}
                loading={isRequesting}
                onClick={() => fetchOutletExpenseList()}
              />
            }
          >
            <Table
              columns={getOutletExpenseTableHeader(handleTableAction)}
              dataSource={tableContents}
              size="small"
              bordered="true"
              scroll={{ x: true }}
              loading={isRequesting}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Return expense"
        centered
        closable={false}
        maskClosable={false}
        open={isReturnModalOpen}
        onOk={handleReturnExpenseSave}
        okText={'Save'}
        confirmLoading={isRequesting}
        onCancel={() => setIsReturnModalOpen(false)}
      >
        <Form
          form={form_return}
          name="incoming_form"
          {...formItemLayout}
          initialValues={selectedExpenseState}
        >
          <FormInput
            type="price"
            name="price"
            placeholder="Please enter amount!"
            label="Amount"
            autoFocus={true}
            value={selectedExpenseState.return_amount}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedExpenseState({
                ...selectedExpenseState,
                return_amount: value,
              });
            }}
            required={true}
          />
        </Form>
      </Modal>
    </Content>
  );
}
