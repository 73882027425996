import { Button, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export function getAccessLevelTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
      title: 'Access Code',
      dataIndex: 'accessCode',
      key: 'accessCode',
      sorter: (a, b) => a.accessCode - b.accessCode,
    },
    {
      title: 'Description',
      dataIndex: 'accessDescription',
      key: 'accessDescription',
      sorter: (a, b) => a.accessDescription.localeCompare(b.accessDescription),
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id)}
            icon={<AiFillEdit size={20} />}
            className="mx-2"
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure to delete ${record.accessDescription}?`}
            onConfirm={() => handleAction(record.id)}
          >
            <Button type="primary" danger icon={<AiFillDelete size={20} />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
}
