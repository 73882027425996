import axios from 'axios';
import {
  ADD_PRICE_ENDPOINT,
  API_CALL_ERROR,
  GET_INCOMING_HISTORY_ENDPOINT,
  GET_PRICE_ENDPOINT,
  GET_RUNNING_INVENTORY_ENDPOINT,
  POST_BULLET_INCOMING_ENDPOINT,
  UPDATE_PRICE_ENDPOINT,
} from './_ConstantsAPI';

export async function getPricePerBranch(branchId) {
  try {
    const { data } = await axios.get(`${GET_PRICE_ENDPOINT}/${branchId}`);
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function addPricePerBranch(payload) {
  try {
    const { data } = await axios.post(ADD_PRICE_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function updatePricePerBranch(payload) {
  try {
    const { data } = await axios.post(UPDATE_PRICE_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
