import { Button, Col, Modal, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { getAccessLevels } from '../../utils/api/AccessLevelAPI';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { getAccessLevelTable } from '../../utils/tableData/Maintenance/AccessLevelTable';
import { IoIosRefresh } from 'react-icons/io';
import { ERROR_NOTIFICATION_TYPE } from '../../utils/api/_ConstantsAPI';

export default function AccessMaintenance() {
  const { authToken, openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [localAccessListData, setLocalAccessListData] = useState([]);

  const tableContents = localAccessListData.map((data, index) => ({
    ...data,
    key: index,
  }));

  const handleAction = (id) => {
    // TODO
    console.log(id);
  };

  const handleModalSave = () => {};

  const fetchAccessLevelsList = async () => {
    setIsLoading(true);
    const levels = await getAccessLevels(profile.id, authToken);
    if (levels.isSuccessful) {
      setLocalAccessListData(levels.data);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Access maintenance',
        'Error fetching data.'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (localAccessListData.length === 0) {
      fetchAccessLevelsList();
    }
  }, []);

  return (
    <Content>
      <Row align={'middle'} className="mb-4">
        <Col xs={12}>
          <h3 className="mb-0">Access Maintenance</h3>
        </Col>
        <Col xs={12} className="text-end">
          <Button
            type="primary"
            size="large"
            icon={<IoIosRefresh size={24} />}
            loading={isLoading}
            onClick={() => fetchAccessLevelsList()}
          />
          <Button
            type="primary"
            size="large"
            icon={<AiOutlinePlus size={24} />}
            loading={isLoading}
            className="ms-3"
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={getAccessLevelTable(handleAction)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <Modal
        title="Access Level Entry"
        open={isModalOpen}
        onOk={handleModalSave}
        confirmLoading={isLoading}
        onCancel={() => setIsModalOpen(false)}
      ></Modal>
    </Content>
  );
}
