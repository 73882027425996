export default {
  isSuccess: false,
  Stock: [
    {
      stock_itemCode: '',
      productName: '',
      stockQty: '',
    },
  ],
  stockSales: [
    {
      totalSales: '',
    },
  ],
};
