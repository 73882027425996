import CustomerDefaultType from '../types/CustomerDefaultType';

export const getCustomerMaintenanceFields = (
  isLoading,
  customerState = CustomerDefaultType,
  setCustomerState
) => {
  return [
    {
      type: 'name',
      name: 'firstName',
      placeholder: 'Juan',
      label: 'First Name',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: customerState.firstName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, firstName: value });
      },
    },
    {
      type: 'name',
      name: 'middleName',
      placeholder: '',
      label: 'Middle Name',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: customerState.middleName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, middleName: value });
      },
    },
    {
      type: 'name',
      name: 'lastName',
      placeholder: 'Dela Cruz',
      label: 'Last Name',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: customerState.lastName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, lastName: value });
      },
    },
    {
      type: 'textarea',
      name: 'address',
      placeholder: 'Address',
      label: 'Address',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: customerState.address,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, address: value });
      },
    },
    {
      type: 'phone',
      name: 'mobileNumber',
      placeholder: '123456789',
      label: 'Mobile number',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: customerState.mobileNumber,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, mobileNumber: value });
      },
    },
    {
      type: 'number',
      name: 'telNumber',
      placeholder: '123456789',
      label: 'Telephone number',
      autofocus: false,
      required: false,
      disabled: isLoading,
      value: customerState.telNumber,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, telNumber: value });
      },
    },
    {
      type: 'dropdown',
      name: 'gender',
      placeholder: 'Gender',
      label: 'Gender',
      required: true,
      disabled: isLoading,
      value: customerState.categoryId,
      dropdownOptions: [
        { id: 'male', description: 'Male' },
        { id: 'female', description: 'Female' },
        { id: 'others', description: 'Others' },
      ],
      setValue: (value, option) => {
        setCustomerState({
          ...customerState,
          gender: value,
        });
      },
    },
    {
      type: 'dropdown',
      name: 'maritalStatus',
      placeholder: 'Marital Status',
      label: 'Marital Status',
      required: true,
      disabled: isLoading,
      value: customerState.maritalStatus,
      dropdownOptions: [
        { id: 'single', description: 'Single' },
        { id: 'married', description: 'Married' },
        { id: 'divorced', description: 'Divorced' },
        { id: 'widowed', description: 'Widowed' },
        { id: 'separated', description: 'Separated' },
        { id: 'civil_partnership', description: 'Civil Partnership' },
        { id: 'other', description: 'Other' },
      ],
      setValue: (value, option) => {
        setCustomerState({
          ...customerState,
          maritalStatus: value,
        });
      },
    },
  ];
};
