import { Form, Input, InputNumber, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Search from 'antd/es/transfer/search';

export default function FormInput({
  type = 'text',
  name,
  placeholder,
  label,
  autoFocus = false,
  value,
  onChange,
  required = true,
  disabled = false,
  dropdownOptions = [],
}) {
  return (
    <>
      {type === 'name' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            onInput={(e) => {
              const inputValue = e.target.value;
              const capitalizedValue =
                inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
              e.target.value = capitalizedValue;
            }}
          />
        </Form.Item>
      )}
      {type === 'password' && (
        <Form.Item
          label={label}
          name={name}
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'text' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <Input
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'number' && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            { required: required, message: 'This field is required.' },
            {
              pattern: /^[0-9]+$/,
              message: `${label}  must contain only digits.`,
            },
          ]}
        >
          <Input
            type="number"
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'quantity' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <InputNumber
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            min={0}
            type="number"
            controls={false}
            style={{
              width: '100%',
            }}
          />
          {/* <Input
            type="number"
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
          /> */}
        </Form.Item>
      )}
      {type === 'price' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <Input
            type="number"
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            prefix="₱"
          />
        </Form.Item>
      )}
      {type === 'textarea' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <TextArea
            placeholder={placeholder}
            autoSize={{
              minRows: 2,
            }}
            disabled={disabled}
            allowClear={true}
            value={value}
            onChange={onChange}
          />
        </Form.Item>
      )}
      {type === 'phone' && (
        <Form.Item
          name={name}
          label={label}
          rules={[
            { required: required, message: 'This field is required.' },
            { len: 9, message: `${label} must have exactly 9 digits/` },
            {
              pattern: /^[0-9]+$/,
              message: `${label}  must contain only digits.`,
            },
          ]}
        >
          <Input
            type="number"
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            onChange={onChange}
            allowClear={true}
            addonBefore={'+639'}
          />
        </Form.Item>
      )}
      {type === 'dropdown' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <Select
            placeholder={placeholder}
            allowClear={true}
            value={value}
            onChange={onChange}
            disabled={disabled}
            showSearch
            filterOption={(inputValue, option) => {
              const optionDescription = option.children.toLowerCase();
              return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
            }}
          >
            {dropdownOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'search' && (
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: required, message: 'This field is required.' }]}
        >
          <Search
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onSearch={onChange}
            allowClear={true}
          />
        </Form.Item>
      )}
      {type === 'no-form-search' && (
        <Search
          loading={disabled}
          enterButton
          size="large"
          placeholder={placeholder}
          onSearch={(value) => onChange(value)}
          onChange={(e) => onChange(e.target.value)}
          allowClear={true}
        />
      )}
    </>
  );
}
