import { Button, Col, Modal, Row, Select, Typography, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { getUsersByBranch } from '../../utils/api/UsersAPI';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { getProducts } from '../../utils/api/ProductsAPI';
import ProductDefaultType from '../../utils/types/ProductDefaultType';
import UserDefaultType from '../../utils/types/UserDefaultType';

export default function CheckerSelection({
  isOpen,
  setIsOpen,
  handleOnFinishSelection,
}) {
  const { authToken, openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const [checkerDataList, setCheckerDataList] = useState([]);
  const [selectedChecker, setSelectedChecker] = useState({});

  const [tanksDataList, setTanksDataList] = useState([]);
  const [selectedTank, setselectedTank] = useState(ProductDefaultType);

  const fetchCheckers = async () => {
    setIsLoading(true);
    const res = await getUsersByBranch(
      profile.id,
      profile.branchCode,
      authToken
    );
    if (res.isSuccessful) {
      const checkers = res.data.filter((record) => record.accessLevel === '4');
      setCheckerDataList(checkers);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Checker select',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const fetchTanks = async () => {
    setIsLoading(true);
    const res = await getProducts();
    if (res.isSuccessful) {
      const tanks = res.data.filter((data) => data.isTank === '1');
      setTanksDataList(tanks);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Checker select',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCheckers();
  }, []);

  return (
    <>
      <Modal
        centered
        closable={false}
        maskClosable={false}
        open={isOpen}
        keyboard={false}
        onOk={setIsOpen}
        footer={null}
        confirmLoading={isLoading}
        onCancel={() => setIsOpen(false)}
      >
        <Row justify="center" align="middle" className="mb-3">
          <Col span={6} className="text-end">
            <Typography.Text strong className="me-2">
              Checker:
            </Typography.Text>
          </Col>
          <Col span={18}>
            <Select
              style={{ width: '100%' }}
              size="large"
              loading={isLoading}
              disabled={isLoading}
              showSearch
              placeholder="Search checker"
              onChange={(value) => {
                const checker = checkerDataList.find(
                  (user) => user.id === value
                );
                setSelectedChecker(checker);
                fetchTanks();
              }}
              onSearch={(value) => console.log(value)}
              filterOption={(inputValue, option) => {
                const optionDescription = option.children
                  .join(' ')
                  .toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
            >
              {checkerDataList.map((record, index) => (
                <Select.Option key={index} value={record.id}>
                  {record.firstName} {record.lastName}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {tanksDataList.length !== 0 && (
          <Row justify="center" align="middle" className="mb-4">
            <Col span={6} className="text-end">
              <Typography.Text strong className="me-2">
                Tank:
              </Typography.Text>
            </Col>
            <Col span={18}>
              <Select
                style={{ width: '100%' }}
                size="large"
                loading={isLoading}
                disabled={isLoading}
                showSearch
                placeholder="Select Tank"
                onChange={(value) => {
                  const tank = tanksDataList.find((data) => data.id === value);
                  setselectedTank(tank);
                }}
                filterOption={(inputValue, option) => {
                  const optionDescription = option.children.toLowerCase();
                  return (
                    optionDescription.indexOf(inputValue.toLowerCase()) >= 0
                  );
                }}
              >
                {tanksDataList.map((record, index) => (
                  <Select.Option key={index} value={record.id}>
                    {record.description}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
        {selectedTank.id !== '' && (
          <Row justify="center" align="middle" className="mb-3">
            <Button
              size="large"
              type="primary"
              onClick={() => {
                handleOnFinishSelection(selectedChecker, selectedTank);
                setSelectedChecker(UserDefaultType);
                setselectedTank(ProductDefaultType);
              }}
            >
              Complete
            </Button>
          </Row>
        )}
      </Modal>
    </>
  );
}
