import { createContext, useState } from 'react';
import ProfileDefaultType from '../types/ProfileDefaultType';
import { format } from 'date-fns-tz';

export const UserContext = createContext();

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const UserProvider = ({ children }) => {
  const [profile, setProfile] = useState(ProfileDefaultType);

  const getCurrentDate = () => {
    return format(new Date(), 'yyyy-MM-dd', {
      timeZone: 'Asia/Manila',
    });
  };

  const value = {
    profile,
    setProfile,
    formItemLayout,
    getCurrentDate,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
