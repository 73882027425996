import { Button, Card, Col, Image, Radio, Row, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useContext, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { UserContext } from '../../utils/context/UserContext';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_ConstantsAPI';
import { timeInOutAPI } from '../../utils/api/TimeKeepingAPI';

export default function TimeClock() {
  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedTimeType, setSelectedTimeType] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(true);

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    setIsCameraOpen(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  }, [webcamRef]);

  const handleSave = async () => {
    setIsLoading(true);
    const payload = {
      empno: profile.id,
      dtrtype: selectedTimeType,
      empimage: capturedImage,
    };
    const res = await timeInOutAPI(payload);
    if (res.isSuccessful) {
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Time clock',
        'Time clock successfully saved.'
      );
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Time clock',
        'Something went wrong.'
      );
    }
    setIsLoading(false);
  };

  return (
    <Content>
      <Typography.Title level={3}>Time clock</Typography.Title>
      <Row justify="center" align="middle">
        <Col xs={24} sm={12}>
          <Card hoverable>
            <Row>
              <Space direction="horizontal" className="mb-2">
                <Typography.Text type="secondary">
                  Logged in as:
                </Typography.Text>
                <Typography.Text
                  strong
                >{`${profile.firstName} ${profile.lastName}`}</Typography.Text>
              </Space>
            </Row>
            {isCameraOpen ? (
              <Webcam
                className="mb-2"
                audio={false}
                height={'100%'}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={'100%'}
              />
            ) : (
              <Image width={'100%'} src={capturedImage} className="mb-2" />
            )}
            <Row>
              <Col xs={24} sm={8} className="mb-3">
                <Radio.Group
                  disabled={isLoading}
                  size="large"
                  buttonStyle="solid"
                  value={selectedTimeType}
                  onChange={(e) => setSelectedTimeType(e.target.value)}
                >
                  <Radio.Button value="I">Time in</Radio.Button>
                  <Radio.Button value="O">Time out</Radio.Button>
                </Radio.Group>
              </Col>
              <Col xs={24} sm={8} className="mb-3">
                <Button
                  disabled={isLoading}
                  size="large"
                  type="primary"
                  onClick={isCameraOpen ? capture : () => setIsCameraOpen(true)}
                >
                  {isCameraOpen ? 'Capture photo' : 'Take another photo'}
                </Button>
              </Col>
              <Col xs={24} sm={8} className="mb-3">
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={handleSave}
                  disabled={
                    selectedTimeType === null ||
                    capturedImage === null ||
                    isLoading
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
