import { Modal } from 'antd';
import React from 'react';

export default function ConfirmationModal({
  isOpen,
  setIsOpen,
  body,
  onConfirm,
}) {
  return (
    <Modal
      centered
      maskClosable={false}
      closable={false}
      open={isOpen}
      onOk={onConfirm}
      onCancel={() => setIsOpen(false)}
      title="Please confirm action!"
    >
      {body}
    </Modal>
  );
}
