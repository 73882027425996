import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Statistic,
  Typography,
  message,
} from 'antd';
import { Content } from 'antd/es/layout/layout';

import React, { useContext, useEffect, useState } from 'react';
import { TbCurrencyPeso } from 'react-icons/tb';
import { UserContext } from '../../utils/context/UserContext';
import {
  addPricePerBranch,
  getPricePerBranch,
  updatePricePerBranch,
} from '../../utils/api/PriceAPI';
import PriceDefaultType from '../../utils/types/PriceDefaultType';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
} from '../../utils/api/_ConstantsAPI';

export default function PriceMaintenance() {
  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [retailPrice, setRetailPrice] = useState(PriceDefaultType);
  const [wholesalePrice, setWholesalePrice] = useState(PriceDefaultType);

  const [isPriceEntryModalOpen, setIsPriceEntryModalOpen] = useState(false);

  const [isEditingWholesale, setIsEditingWholesale] = useState(false);
  const [currentPriceState, setCurrentPriceState] = useState(PriceDefaultType);

  const getBranchPrices = async () => {
    setIsLoading(true);
    const res = await getPricePerBranch(profile.branchCode);
    if (res.isSuccess) {
      const retail = res.priceList.find((item) => item.is_wholesale === '0');
      const wholesale = res.priceList.find((item) => item.is_wholesale === '1');
      if (retail !== null && retail !== undefined) {
        setRetailPrice(retail);
      }
      if (wholesale !== null && wholesale !== undefined) {
        setWholesalePrice(wholesale);
      }
    }
    setIsLoading(false);
  };

  const handlePriceAction = async () => {
    setIsLoading(true);

    const payload = {
      branch: profile.branchCode,
      description: isEditingWholesale ? 'Wholesale price' : 'Retail price',
      amount: currentPriceState.amount,
      is_wholesale: isEditingWholesale ? '1' : '0',
      updatedBy: profile.id,
    };

    if (isEditingWholesale) {
      if (wholesalePrice.id === '') {
        // Add flow
        const res = await addPricePerBranch(payload);
        if (res.isSuccess) {
          getBranchPrices();
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Price update',
            'Price updated successfully.'
          );
          setIsPriceEntryModalOpen(false);
          setIsLoading(false);
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Price update',
            'Something went wrong.'
          );
        }
      } else {
        // Update flow
        const payload = {
          ...currentPriceState,
          branch: profile.branchCode,
          updatedBy: profile.id,
        };
        const res = await updatePricePerBranch(payload);
        if (res.isSuccess) {
          getBranchPrices();
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Price update',
            'Price updated successfully.'
          );
          setIsPriceEntryModalOpen(false);
          setIsLoading(false);
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Price update',
            'Something went wrong.'
          );
        }
      }
    } else {
      if (retailPrice.id === '') {
        // Add flow
        const res = await addPricePerBranch(payload);
        if (res.isSuccess) {
          getBranchPrices();
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Price update',
            'Price updated successfully.'
          );
          setIsPriceEntryModalOpen(false);
          setIsLoading(false);
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Price update',
            'Something went wrong.'
          );
        }
      } else {
        // Update flow
        const payload = {
          ...currentPriceState,
          branch: profile.branchCode,
          updatedBy: profile.id,
        };
        const res = await updatePricePerBranch(payload);
        if (res.isSuccess) {
          getBranchPrices();
          openNotification(
            SUCCESS_NOTIFICATION_TYPE,
            'Price update',
            'Price updated successfully.'
          );
          setIsPriceEntryModalOpen(false);
          setIsLoading(false);
        } else {
          openNotification(
            ERROR_NOTIFICATION_TYPE,
            'Price update',
            'Something went wrong.'
          );
        }
      }
    }
  };

  useEffect(() => {
    getBranchPrices();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Price maintenance</Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Card bordered={false} loading={isLoading}>
            {retailPrice.id !== '' && (
              <Statistic
                title={retailPrice.description}
                value={retailPrice.amount}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<TbCurrencyPeso />}
              />
            )}
            <Button
              type="primary"
              className="mt-2"
              onClick={() => {
                setCurrentPriceState(retailPrice);
                setIsEditingWholesale(false);
                setIsPriceEntryModalOpen(true);
              }}
            >
              {retailPrice.id === ''
                ? 'Add Retail price'
                : 'Update Retail price'}
            </Button>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} loading={isLoading}>
            {wholesalePrice.id !== '' && (
              <Statistic
                title={wholesalePrice.description}
                value={wholesalePrice.amount}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<TbCurrencyPeso />}
              />
            )}
            <Button
              type="primary"
              className="mt-2"
              onClick={() => {
                setCurrentPriceState(wholesalePrice);
                setIsEditingWholesale(true);
                setIsPriceEntryModalOpen(true);
              }}
            >
              {wholesalePrice.id === ''
                ? 'Add Wholesale price'
                : 'Update Wholesale price'}
            </Button>
          </Card>
        </Col>
      </Row>
      <Modal
        centered
        closable={false}
        maskClosable={false}
        open={isPriceEntryModalOpen}
        keyboard={false}
        onOk={() => {
          handlePriceAction();
        }}
        confirmLoading={isLoading}
        onCancel={() => setIsPriceEntryModalOpen(false)}
        okText="Save"
      >
        <Typography.Title level={4}>
          Please enter new price amount:
        </Typography.Title>
        <InputNumber
          placeholder={'XXX.XX'}
          disabled={isLoading}
          value={currentPriceState.amount}
          onChange={(value) =>
            setCurrentPriceState({ ...currentPriceState, amount: value })
          }
          min={0}
          type="number"
          controls={false}
          style={{
            width: '100%',
            fontSize: '30px',
          }}
        />
      </Modal>
    </Content>
  );
}
