import { Card, Col, Form, Image, Modal, Row, Typography } from 'antd';
import { UserContext } from '../../utils/context/UserContext';
import Meta from 'antd/es/card/Meta';
import Search from 'antd/es/input/Search';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useState } from 'react';
import {
  addEditCustomer,
  getCustomerBySearch,
} from '../../utils/api/CustomersAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import CustomerDefaultType from '../../utils/types/CustomerDefaultType';
import FormInput from '../../components/FormInput/FormInput';
import { getCustomerMaintenanceFields } from '../../utils/data/CustomerField';
import { GlobalContext } from '../../App';

export default function CustomerSelection({ onSelectCustomer }) {
  const { profile, formItemLayout } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isAnonymousModalOpen, setIsAnonymousModalOpen] = useState(false);

  const [localCustomerList, setLocalCustomerList] = useState([]);

  const [isRegisterCxModalOpen, setIsRegisterCxModalOpen] = useState(false);
  const [customerState, setCustomerState] = useState(CustomerDefaultType);

  const handleCustomerSearch = async (value) => {
    if (value.length > 2) {
      setIsLoading(true);
      const res = await getCustomerBySearch(value);
      if (res.isSuccessful) {
        setLocalCustomerList(res.data);
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Customer selection',
          getAPIResponseMessage(res.data)
        );
      }
      setIsLoading(false);
    }
  };

  const handleRegisterModalSave = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();

      const res = await addEditCustomer(profile.id, customerState);
      if (res.isSuccessful) {
        setIsRegisterCxModalOpen(false);
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Customer selection',
          'Customer added successfully!'
        );
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Customer selection',
          getAPIResponseMessage(res.data)
        );
      }
    } catch (error) {
      // No action for now.
    }
    setIsLoading(false);
  };

  return (
    <Content>
      <Row justify="center" align="middle">
        <Col xs={24} sm={12} md={8}>
          <Search
            loading={isLoading}
            enterButton
            size="large"
            placeholder="Start searching your name!"
            onSearch={(value) => handleCustomerSearch(value)}
            onChange={(e) => handleCustomerSearch(e.target.value)}
            allowClear={true}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24} className="text-center mt-2">
          <Typography.Text
            type="secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsAnonymousModalOpen(true)}
          >
            Continue as anonymous customer?
          </Typography.Text>
        </Col>
        <Col xs={24} className="text-center mt-2">
          <Typography.Text
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => setIsRegisterCxModalOpen(true)}
          >
            Register a new customer?
          </Typography.Text>
        </Col>
      </Row>
      <Row className="mt-4" gutter={[32, 32]}>
        {localCustomerList.map((customer, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card
              hoverable
              cover={
                <Image src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
              }
              onClick={() => {
                onSelectCustomer(customer);
              }}
            >
              <Meta
                title={`${customer.firstName} ${customer.middleName} ${customer.lastName}`}
                className="mb-2"
              />
              {customer.address && (
                <Meta
                  description={`Address: ${customer.address}`}
                  className="mb-2"
                />
              )}
              {customer.mobileNumber && (
                <Meta
                  description={`Mobile: ${customer.mobileNumber}`}
                  className="mb-2"
                />
              )}
              {customer.gender && (
                <Meta
                  description={`Gender: ${customer.gender}`}
                  className="mb-2"
                />
              )}
              {customer.maritalStatus && (
                <Meta
                  description={`Marital Status: ${customer.maritalStatus}`}
                  className="mb-2"
                />
              )}
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        centered
        closable={false}
        title="Are you sure to proceed anonymously?"
        open={isAnonymousModalOpen}
        onOk={() => {
          const anonymousUser = CustomerDefaultType;
          anonymousUser.id = 0;
          anonymousUser.firstName = 'Anonymous';
          onSelectCustomer(anonymousUser);
          setIsAnonymousModalOpen(false);
        }}
        onCancel={() => setIsAnonymousModalOpen(false)}
        okText="Proceed"
      />
      <Modal
        title="Customer Entry"
        centered
        closable={false}
        maskClosable={false}
        open={isRegisterCxModalOpen}
        onOk={handleRegisterModalSave}
        okText="Save"
        confirmLoading={isLoading}
        onCancel={() => setIsRegisterCxModalOpen(false)}
      >
        <Form
          form={form}
          name="customer_form"
          {...formItemLayout}
          initialValues={customerState}
        >
          {getCustomerMaintenanceFields(
            isLoading,
            customerState,
            setCustomerState
          ).map((data, index) => (
            <FormInput
              key={index}
              type={data.type}
              name={data.name}
              placeholder={data.placeholder}
              label={data.label}
              autoFocus={data.autofocus}
              value={data.value}
              onChange={data.setValue}
              required={data.required}
              disabled={data.disabled}
              dropdownOptions={data.dropdownOptions}
            />
          ))}
        </Form>
      </Modal>
    </Content>
  );
}
