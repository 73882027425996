export default {
  id: '',
  image: 'uploads/profile/default_profile_pic.png',
  firstName: '',
  middleName: '',
  lastName: '',
  address: '',
  mobileNumber: '',
  telNumber: '',
  gender: '',
  maritalStatus: '',
  isDeleted: '',
  updatedBy: '',
};
