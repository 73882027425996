export const getUserMaintenanceFields = (
  isLoading,
  userState,
  setUserState,
  accessLevelOptions
) => {
  return [
    {
      type: 'text',
      name: 'username',
      placeholder: 'Please enter desired username.',
      label: 'Username',
      autofocus: true,
      required: true,
      disabled: isLoading,
      value: userState.username,
      setValue: (e) => {
        const value = e.target.value;
        setUserState({ ...userState, username: value });
      },
    },
    {
      type: 'name',
      name: 'firstName',
      placeholder: 'Juan',
      label: 'First Name',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: userState.firstName,
      setValue: (e) => {
        const value = e.target.value;
        setUserState({ ...userState, firstName: value });
      },
    },
    {
      type: 'name',
      name: 'lastName',
      placeholder: 'Dela Cruz',
      label: 'Last Name',
      autofocus: false,
      required: true,
      disabled: isLoading,
      value: userState.lastName,
      setValue: (e) => {
        const value = e.target.value;
        setUserState({ ...userState, lastName: value });
      },
    },
    {
      type: 'dropdown',
      name: 'accessLevel',
      placeholder: 'Access Level',
      label: 'Access Level',
      required: true,
      disabled: isLoading,
      value: userState.accessLevel,
      dropdownOptions: accessLevelOptions,
      setValue: (value) => {
        setUserState({ ...userState, accessLevel: value });
      },
    },
  ];
};
