import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import {
  getCheckerHistoryReportAPI,
  getSalesHistory,
} from '../../utils/api/ReportsAPI';
import { detailedSalesHistoryReportHeader } from '../../utils/tableData/Report/SalesHistoryReport';
import { GlobalContext } from '../../App';
import {
  ERROR_NOTIFICATION_TYPE,
  WARNING_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { PDFViewer } from '@react-pdf/renderer';
import { PrinterFilled } from '@ant-design/icons';
import OutletDefaultType from '../../utils/types/OutletDefaultType';
import { getOutletList } from '../../utils/api/OutletAPI';
import { getOutletHistoryReportHeader } from '../../utils/tableData/Report/OutletHistoryReportHeader';
import { UserContext } from '../../utils/context/UserContext';
import { CheckerHistoryPrint, OutletHistoryPrint } from '../../components';
import { getUsersByBranch } from '../../utils/api/UsersAPI';
import ProfileDefaultType from '../../utils/types/ProfileDefaultType';
import { getCheckerHistoryReportHeader } from '../../utils/tableData/Report/CheckerHistoryReportHeader';

export default function CheckerHistoryReport() {
  const { profile } = useContext(UserContext);
  const { authToken, openNotification } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const [localSalesHistory, setLocalSalesHistory] = useState([]);

  const [selectedSalesHistory, setSelectedSalesHistory] = useState({});
  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [localCheckersList, setLocalCheckersList] = useState([]);
  const [selectedChecker, setSelectedChecker] = useState(ProfileDefaultType);

  const tableContents = localSalesHistory.map((data, index) => ({
    ...data,
    key: index,
    id: index,
    customer_name:
      data.customer_name === ' ' ? 'Anonymous customer' : data.customer_name,
  }));

  const handleTableActions = (id) => {
    const record = tableContents.find((value) => value.id === id);
    setSelectedSalesHistory(record);
    setIsDetailedModalOpen(true);
  };

  const onProcessRequest = async () => {
    setIsLoading(true);
    const res = await getCheckerHistoryReportAPI(
      dateFrom,
      dateTo,
      profile.branchCode,
      selectedChecker.id
    );
    if (res.length > 0) {
      setLocalSalesHistory(res);
    } else {
      setLocalSalesHistory([]);
      openNotification(
        WARNING_NOTIFICATION_TYPE,
        'Checker history',
        res.message
      );
    }
    setIsLoading(false);
  };

  const getCheckers = async () => {
    setIsLoading(true);
    const res = await getUsersByBranch(
      profile.id,
      profile.branchCode,
      authToken
    );
    if (res.isSuccessful) {
      const checkers = res.data.filter((record) => record.accessLevel === '4');
      setLocalCheckersList(checkers);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Sales history report.',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCheckers();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Checker History</Typography.Title>
      <Row>
        <Col xs={24} sm={18}>
          <Space.Compact style={{ width: '100%' }}>
            <Select
              loading={isLoading}
              disabled={isLoading}
              showSearch
              placeholder="Search checkers"
              onChange={(value) => {
                const checker = localCheckersList.find(
                  (user) => user.id === value
                );
                setSelectedChecker(checker);
              }}
              onSearch={(value) => console.log(value)}
              filterOption={(inputValue, option) => {
                const optionDescription = option.children
                  .join(' ')
                  .toLowerCase();
                return optionDescription.indexOf(inputValue.toLowerCase()) >= 0;
              }}
            >
              {localCheckersList.map((record, index) => (
                <Select.Option key={index} value={record.id}>
                  {record.firstName} {record.lastName}
                </Select.Option>
              ))}
            </Select>
            <DatePicker
              onChange={(_, dateString) => {
                setDateFrom(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <DatePicker
              onChange={(_, dateString) => {
                setDateTo(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <Button
              type="primary"
              disabled={
                isLoading ||
                selectedChecker.id === null ||
                dateFrom === '' ||
                dateTo === ''
              }
              onClick={() => onProcessRequest()}
            >
              Process
            </Button>
          </Space.Compact>
        </Col>
        <Col sm={6}>
          <Button
            block
            icon={<PrinterFilled />}
            onClick={() => setIsPrintModalOpen(true)}
            disabled={localSalesHistory?.length === 0 || isLoading}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Table
        columns={getCheckerHistoryReportHeader(handleTableActions)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true, y: 350 }}
        loading={isLoading}
        className="mt-3"
      />
      <Modal
        centered
        closable={false}
        open={isDetailedModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsDetailedModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'80vw'}
        title="Detailed report"
      >
        <Descriptions
          className="mt-4"
          bordered
          size="small"
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Customer">
            {selectedSalesHistory.customer_name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {selectedSalesHistory.date}
          </Descriptions.Item>
          <Descriptions.Item label="Order number">
            {selectedSalesHistory.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Total QTY">
            {selectedSalesHistory.salesQty}
          </Descriptions.Item>
          <Descriptions.Item label="Price per KG">
            {selectedSalesHistory.price_per_kg}
          </Descriptions.Item>
          <Descriptions.Item label="Total Amount">
            {selectedSalesHistory.total_amount}
          </Descriptions.Item>
          <Descriptions.Item label="Paid amount">
            {selectedSalesHistory.amount_paid}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {selectedSalesHistory.transtype}
          </Descriptions.Item>
          <Descriptions.Item label="Order details" span={2}>
            <Table
              columns={detailedSalesHistoryReportHeader}
              dataSource={selectedSalesHistory?.orderList?.map(
                (data, index) => ({
                  ...data,
                  key: index,
                })
              )}
              size="small"
              bordered="true"
              loading={isLoading}
            />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Modal
        centered
        closable={false}
        open={isPrintModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsPrintModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'90vw'}
      >
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <CheckerHistoryPrint
            selectedChecker={selectedChecker}
            type={'3'}
            records={localSalesHistory}
            date={`${dateFrom} to ${dateTo}`}
            profile={profile}
          />
        </PDFViewer>
      </Modal>
    </Content>
  );
}
