import { Col, Form, Layout, Menu, Row, Typography, theme, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LOG_OUT_ROUTE } from '../../utils/router/routes';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { adminItems, cashierItems, checkerItems, poItems } from './NavItems';

import { FaUserAlt } from 'react-icons/fa';
import { changePassword } from '../../utils/api/UsersAPI';
import { getChangePasswordFields } from '../../utils/data/ChangePasswordField';
import FormInput from '../FormInput/FormInput';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import Cookies from 'js-cookie';

const { Sider, Footer, Content } = Layout;

export default function Navigation({ children }) {
  const { profile, formItemLayout } = useContext(UserContext);
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const [form] = Form.useForm();

  const { setIsLoggedIn, authToken, openNotification } =
    useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const originalPayload = {
    id: profile.id,
    updatedBy: profile.id,
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  const [changePasswordPayload, setChangePasswordPayload] =
    useState(originalPayload);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const getNavItems = () => {
    switch (profile.accessLevel) {
      case '1':
        return adminItems;
      case '2':
        return poItems;
      case '3':
        return cashierItems;
      case '4':
        return checkerItems;

      default:
        break;
    }
  };

  const onChangePassword = async () => {
    setIsLoading(true);
    if (
      changePasswordPayload.newPassword ===
      changePasswordPayload.confirmPassword
    ) {
      const response = await changePassword(
        profile.id,
        changePasswordPayload,
        authToken
      );

      if (response.isSuccessful) {
        openNotification(
          SUCCESS_NOTIFICATION_TYPE,
          'Password change request.',
          getAPIResponseMessage(response.data)
        );
        setIsModalOpen(false);
        resetFields();
      } else {
        openNotification(
          ERROR_NOTIFICATION_TYPE,
          'Password change request.',
          getAPIResponseMessage(response.data)
        );
      }
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Password change request.',
        'Password does not match'
      );
    }

    setIsLoading(false);
  };

  const resetFields = () => {
    setChangePasswordPayload(originalPayload);
    form.resetFields();
  };
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="dark"
      >
        {/* TODO */}
        <div
          className="demo-logo-vertical"
          onClick={() => setIsModalOpen(true)}
        >
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
              <FaUserAlt size={20} color="white" className="me-2" />
            </Col>
            {!collapsed && (
              <Col>
                <Typography.Text
                  strong
                  style={{ color: 'white' }}
                >{`${profile.firstName} ${profile.lastName}`}</Typography.Text>
              </Col>
            )}
          </Row>
        </div>
        <Menu
          onClick={(e) => {
            if (e.key === LOG_OUT_ROUTE) {
              // log user out
              Cookies.remove('username');
              Cookies.remove('password');
              Cookies.remove('is-logged-in');
              setIsLoggedIn(false);
            } else {
              navigate(e.key);
            }
          }}
          theme="dark"
          defaultSelectedKeys={[currentLocation.pathname]}
          mode="inline"
          items={getNavItems()}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            paddingTop: 0,
            textAlign: 'center',
          }}
        >
          XGen Innovative Technologies Corp.
        </Footer>
      </Layout>
      <Modal
        centered
        maskClosable={false}
        closable={false}
        open={isModalOpen}
        onOk={() => onChangePassword()}
        onCancel={() => {
          setIsModalOpen(false);
          resetFields();
        }}
        title="Please confirm action!"
      >
        <Form
          form={form}
          name="change_password_form"
          {...formItemLayout}
          initialValues={changePasswordPayload}
          disabled={isLoading}
        >
          {getChangePasswordFields(
            isLoading,
            changePasswordPayload,
            setChangePasswordPayload
          ).map((data, index) => (
            <FormInput
              key={index}
              type={data.type}
              name={data.name}
              placeholder={data.placeholder}
              label={data.label}
              autoFocus={data.autofocus}
              value={data.value}
              onChange={data.setValue}
              required={data.required}
              disabled={data.disabled}
              dropdownOptions={data.dropdownOptions}
            />
          ))}
        </Form>
      </Modal>
    </Layout>
  );
}
