import React, { useContext } from 'react';
import { UserContext } from '../../utils/context/UserContext';
import { format } from 'date-fns-tz';
import { Card, Col, Row } from 'antd';
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { Content } from 'antd/es/layout/layout';
import EndOfDaySummaryDefaultType from '../../utils/types/EndOfDaySummaryDefaultType';
import TenderDefaultType from '../../utils/types/TenderDefaultType';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  container: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderWidth: 1,
    paddingVertical: 4,
    paddingLeft: 3,
    borderBottomWidth: 1,
    marginBottom: 3,
    textAlign: 'left',
  },
  column: {
    flexGrow: 1,
    marginRight: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  fieldName: {
    color: 'grey',
    fontSize: 8,
  },
  fieldValue: {
    fontSize: 10,
    color: 'black',
  },
  denomName: {
    color: 'grey',
    fontSize: 8,
  },
  denomValue: {
    fontSize: 10,
    color: 'black',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '33.33%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    padding: 5,
    fontSize: '8px',
  },
});

export default function EndOfDayPrint({
  eodSummary = EndOfDaySummaryDefaultType,
  tenderDeclaration = TenderDefaultType,
  denominationTotal,
}) {
  const { profile, getCurrentDate } = useContext(UserContext);
  return (
    <Content>
      <Row>
        <Col xs={24}>
          <Card hoverable bordered>
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
              <Document>
                <Page
                  size={'LETTER'}
                  style={styles.body}
                  orientation="portrait"
                >
                  <Text style={styles.header} fixed>
                    C - 101 - FINAL SUMMARY REPORT
                  </Text>
                  <View
                    style={{
                      ...styles.container,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={styles.fieldName}>
                        CASHIER:{' '}
                        <Text
                          style={styles.fieldValue}
                        >{`${profile.firstName} ${profile.lastName}`}</Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.fieldName}>
                        Date:{' '}
                        <Text style={styles.fieldValue}>
                          {getCurrentDate()}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.container,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 10 }}>
                        OPR-R#:{' '}
                        <Text style={{ ...styles.fieldValue, fontSize: 12 }}>
                          {eodSummary.oprR}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 10 }}>
                        OPR-D#:{' '}
                        <Text style={{ ...styles.fieldValue, fontSize: 12 }}>
                          {eodSummary.oprD}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 10 }}>
                        OPR-R:{' '}
                        <Text style={{ ...styles.fieldValue, fontSize: 12 }}>
                          {eodSummary.retailSales}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 10 }}>
                        OPR-D:{' '}
                        <Text style={{ ...styles.fieldValue, fontSize: 12 }}>
                          {eodSummary.wholesaleSales}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 10 }}>
                        TOTAL SALES:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                            fontSize: 12,
                            color: 'green',
                          }}
                        >
                          {eodSummary.totalSales}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        ADD:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.add}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        LESS:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.less}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName, fontSize: 12 }}>
                        TOTAL:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                            fontSize: 14,
                            color: 'green',
                          }}
                        >
                          {eodSummary.totalSales}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={styles.container}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        Opening Grandtotal Sales:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.oldgt}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        Closing Grandtotal Sales:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.newgt}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={{ ...styles.container, marginBottom: 5 }}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        Opening Inventory:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.oldinv}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        Closing Inventory:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.newgtinv}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={{ ...styles.container, marginBottom: 5 }}>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        Retail sales in KG:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {eodSummary.retailrefill}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <Text style={{ fontSize: 12 }}>Denomination</Text>
                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        1000:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.thousands}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        10:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.tens}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        500:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.fiveHundreds}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        5:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.fives}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        200:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.twoHundreds}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        1:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.peso}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        100:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.oneHundreds}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        25¢:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                          }}
                        >
                          {tenderDeclaration.twentyFiveCents}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        50:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.fifties}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        5¢:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.fiveCents}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        20:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                          }}
                        >
                          {tenderDeclaration.twenties}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.column}>
                      <Text style={{ ...styles.denomName }}>
                        1¢:{' '}
                        <Text
                          style={{
                            ...styles.denomValue,
                            width: '60%',
                          }}
                        >
                          {tenderDeclaration.oneCent}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      ...styles.container,
                      marginBottom: 50,
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        DENOMINATION :{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                            fontSize: 12,
                          }}
                        >
                          {denominationTotal}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      ...styles.container,
                      border: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        CASHIER SIGNATURE:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                            fontSize: 16,
                          }}
                        >
                          _____________________________
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      ...styles.container,
                      border: 0,
                    }}
                  >
                    <View style={styles.column}>
                      <Text style={{ ...styles.fieldName }}>
                        ENCODER SIGNATURE:{' '}
                        <Text
                          style={{
                            ...styles.fieldValue,
                            fontSize: 16,
                          }}
                        >
                          _____________________________
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                      `${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </Page>
              </Document>
            </PDFViewer>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
