import { Button, Col, Modal, Row, Steps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useState } from 'react';
import CustomerSelection from './CustomerSelection';
import ContainerSelection from './ContainerSelection';
import { UserContext } from '../../utils/context/UserContext';
import ConfirmationPage from './ConfirmationPage';
import CustomerDefaultType from '../../utils/types/CustomerDefaultType';
import { saveOrder } from '../../utils/api/TransactionAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import { SuccessModal } from '../../components';
import { GlobalContext } from '../../App';
import CustomModal from '../../components/Modals/CustomModal';
import OrderPrint from '../../components/PDFDocuments/OrderPrint';

export default function SalesOrder() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const { openNotification } = useContext(GlobalContext);
  const { profile } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [cart, setCart] = useState([]);

  const [orderNumber, setOrderNumber] = useState(false);

  const [isConfirmOrderModalOpen, setIsConfirmOrderModalOpen] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleOnSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setCurrentStep(currentStep + 1);
  };

  const handleOnConfirmOrder = async () => {
    setIsLoading(true);
    const payload = {
      orderBranch: profile.branchCode,
      orderCustomer: selectedCustomer.id,
      productList: cart.map((order) => ({
        productId: order.product.id,
        productQty: order.orderQty,
      })),
    };
    const res = await saveOrder(payload);
    if (res.isSuccessful) {
      setOrderNumber(res.orderNumber);
      setIsSuccessModalOpen(true);
      setCart([]);
      setSelectedCustomer({ ...selectedCustomer, CustomerDefaultType });
      setCurrentStep(0);
      setIsLoading(false);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Sales order',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const steps = [
    {
      title: 'Customer Details',
      content: <CustomerSelection onSelectCustomer={handleOnSelectCustomer} />,
    },
    {
      title: 'Container Details ',
      content: <ContainerSelection cart={cart} setCart={setCart} />,
    },
    {
      title: 'Confirmation',
      content: (
        <ConfirmationPage
          selectedCustomer={selectedCustomer}
          cart={cart}
          setCurrentStep={setCurrentStep}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Content>
      <Steps
        current={currentStep}
        items={items}
        className='mb-4'
        onChange={(value) => {
          if (value < currentStep) {
            setCurrentStep(value);
          }
        }}
      />
      <Row>
        <Col xs={8}>
          {currentStep >= 1 && (
            <Button
              className='mb-4'
              type='primary'
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Prev
            </Button>
          )}
        </Col>
        <Col xs={8} className='text-center'>
          {currentStep === 2 && (
            <Button
              loading={isLoading}
              className='mb-4'
              type='primary'
              size='large'
              onClick={() => setIsConfirmOrderModalOpen(true)}
            >
              Confirm order!
            </Button>
          )}
        </Col>
        <Col xs={8} className='text-end'>
          {cart.length > 0 && currentStep === 1 && (
            <Button
              className='mb-4'
              type='primary'
              style={{ whiteSpace: 'normal', height: 'auto' }}
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Proceed to checkout
            </Button>
          )}
        </Col>
      </Row>

      <Content>{steps[currentStep].content}</Content>
      <Modal
        centered
        closable={false}
        open={isConfirmOrderModalOpen}
        title='Kindly confirm your order.'
        onOk={() => {
          handleOnConfirmOrder();
          setIsConfirmOrderModalOpen(false);
        }}
        onCancel={() => setIsConfirmOrderModalOpen(false)}
        okText='Confirm'
      />
      {isMobile ? (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          setIsOpen={setIsSuccessModalOpen}
          orderNumber={orderNumber}
        />
      ) : (
        <CustomModal
          isLoading={isLoading}
          isOpen={isSuccessModalOpen}
          setIsOpen={setIsSuccessModalOpen}
          cancelText='Close'
          props={{ okButtonProps: { hidden: true }, width: '90vw' }}
          isClosable={true}
        >
          <OrderPrint orderNumber={orderNumber} />
        </CustomModal>
      )}
    </Content>
  );
}
