import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useState } from 'react';
import { getSalesHistory } from '../../utils/api/ReportsAPI';
import { UserContext } from '../../utils/context/UserContext';
import {
  detailedSalesHistoryReportHeader,
  getSalesHistoryReportHeader,
} from '../../utils/tableData/Report/SalesHistoryReport';
import { GlobalContext } from '../../App';
import { WARNING_NOTIFICATION_TYPE } from '../../utils/api/_ConstantsAPI';
import { PDFViewer } from '@react-pdf/renderer';
import { SalesHistoryPrint } from '../../components';
import { PrinterFilled } from '@ant-design/icons';

export default function SalesHistoryReport() {
  const { profile } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const [localSalesHistory, setLocalSalesHistory] = useState([]);

  const [selectedTransType, setSelectedTransType] = useState('');

  const [selectedSalesHistory, setSelectedSalesHistory] = useState({});
  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const tableContents = localSalesHistory.map((data, index) => ({
    ...data,
    key: index,
    id: index,
    customer_name:
      data.customer_name === ' ' ? 'Anonymous customer' : data.customer_name,
  }));

  const handleTableActions = (id) => {
    const record = tableContents.find((value) => value.id === id);
    setSelectedSalesHistory(record);
    setIsDetailedModalOpen(true);
  };

  const onProcessRequest = async () => {
    setIsLoading(true);
    const res = await getSalesHistory(
      profile.branchCode,
      dateFrom,
      dateTo,
      selectedTransType,
      '0'
    );
    if (res.isSuccess) {
      if (res.salesHistory.length > 0) {
        setLocalSalesHistory(res.salesHistory);
      }
    } else {
      setLocalSalesHistory([]);
      openNotification(WARNING_NOTIFICATION_TYPE, 'Sales History', res.message);
    }
    setIsLoading(false);
  };

  return (
    <Content>
      <Typography.Title level={3}>Sales History</Typography.Title>
      <Row>
        <Col xs={24} sm={18}>
          <Space.Compact style={{ width: '100%' }}>
            <Select
              style={{ width: '50%' }}
              loading={isLoading}
              disabled={isLoading}
              placeholder="Select transaction type"
              onChange={(value) => setSelectedTransType(value)}
            >
              <Select.Option value={'1'}>Retail</Select.Option>
              <Select.Option value={'2'}>Wholesale</Select.Option>
            </Select>
            <DatePicker
              onChange={(_, dateString) => {
                setDateFrom(dateString);
                setDateTo(dateString);
              }}
              format="YYYY-MM-DD"
              disabled={isLoading}
            />
            <Button
              type="primary"
              disabled={
                isLoading ||
                selectedTransType === '' ||
                dateFrom === '' ||
                dateTo === ''
              }
              onClick={() => onProcessRequest()}
            >
              Process
            </Button>
          </Space.Compact>
        </Col>
        <Col sm={6}>
          <Button
            block
            icon={<PrinterFilled />}
            onClick={() => setIsPrintModalOpen(true)}
            disabled={localSalesHistory?.length === 0 || isLoading}
          >
            Print
          </Button>
        </Col>
      </Row>
      <Table
        columns={getSalesHistoryReportHeader(handleTableActions)}
        dataSource={tableContents}
        size="small"
        bordered="true"
        scroll={{ x: true, y: 350 }}
        loading={isLoading}
        className="mt-3"
      />
      <Modal
        centered
        closable={false}
        open={isDetailedModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsDetailedModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'80vw'}
        title="Detailed report"
      >
        <Descriptions
          className="mt-4"
          bordered
          size="small"
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Customer">
            {selectedSalesHistory.customer_name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {selectedSalesHistory.date}
          </Descriptions.Item>
          <Descriptions.Item label="Order number">
            {selectedSalesHistory.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Total QTY">
            {selectedSalesHistory.salesQty}
          </Descriptions.Item>
          <Descriptions.Item label="Total amount">
            {selectedSalesHistory.total_amount}
          </Descriptions.Item>
          <Descriptions.Item label="Amount paid">
            {selectedSalesHistory.amount_paid}
          </Descriptions.Item>
          <Descriptions.Item label="Order details" span={2}>
            <Table
              columns={detailedSalesHistoryReportHeader}
              dataSource={selectedSalesHistory?.orderList?.map(
                (data, index) => ({
                  ...data,
                  key: index,
                })
              )}
              size="small"
              bordered="true"
              loading={isLoading}
            />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Modal
        centered
        closable={false}
        open={isPrintModalOpen}
        confirmLoading={isLoading}
        onCancel={() => setIsPrintModalOpen(false)}
        cancelText="Close"
        okButtonProps={{ hidden: true }}
        width={'90vw'}
      >
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <SalesHistoryPrint
            type={selectedTransType}
            records={localSalesHistory}
            date={dateFrom}
            profile={profile}
          />
        </PDFViewer>
      </Modal>
    </Content>
  );
}
