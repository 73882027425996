const BASE_ENDPOINT = 'https://hermitapi.xgitcorp.com';

const PER_USER_ENDPOINT = '/user';
const USERS_ENDPOINT = '/users';
const LOGIN = '/users/login';
const REGISTER = '/users/register';
const DEACTIVATE_USER = '/users/deactivate';
const CHANGE_PASSWORD = '/users/change-password';

const CUSTOMERS = '/customers';
const CATEGORIES = '/categories';
const PRODUCTS = '/products';
const BRANCHES = '/branches';
const ADD_PRODUCT = '/maintenance/addProduct';
const UPDATE_PRODUCT = '/maintenance/updateProduct';
const DELETE_PRODUCT = '/maintenance/deleteProduct';
const RUNNING_INVENTORY = '/inventory/runningInv';
const INCOMING_HISTORY = '/reports/getincomeHistPerItem';
const BULLET_INCOMING = '/inventory/bulletincome';
const ADD_PRICE = '/maintenance/addprice';
const UPDATE_PRICE = '/maintenance/updateprice';
const GET_PRICE = '/maintenance/getprice';

// TRANSACTIONS API
const SAVE_ORDER = '/transaction/order';
const GET_ORDER = '/transaction/retrieveorder';
const POST_ORDER = '/transaction/postOrder';

// REPORTS API
const GET_SALES_HISTORY = '/reports/getsalesHist';
const GET_DAILY_STOCKS_REPORT = '/reports/dsr';
const CHECKER_HISTORY_REPORT = '/reports/getcheckerhist';
const CUSTOMER_HISTORY_REPORT = '/reports/getcustomerHist';

// OUTLET API
const SAVE_OUTLET_ORDER = '/transaction/order_rr';
const GET_OUTLET_ORDER = '/transaction/retrieveorder_rr';
const POST_OUTLET_ORDER = '/transaction/postorder_rr';
const GET_OUTLET_LIST = '/outlet';

// EXPENSE API
const GET_EXPENSE_CATEGORY = '/expense/expenseCategory';
const POST_EXPENSE_REQUEST = '/expense/requestexpense';
const GET_EXPENSE_LIST = '/expense/getexpense';
const POST_RETURN_EXPENSE = '/expense/expensereturn';

// TIMEKEEPING API
const TIME_IN_OUT = '/transaction/dtr';

// END OF DAY API
const PROCESS_EOD = '/transaction/processeod';
const POST_EOD = '/transaction/posteod';
const POST_TENDER_DECLARATION = '/transaction/tenderdec';

// SOLID ASSETS API
const GET_SOLID_ASSET_PRODUCTS = '/productssa';
const POST_SOLID_ASSET_TRANSACTION = '/transaction/postsa';

const ACCESS_LEVEL = '/access-level';

export const IMAGE_URL_BASE_PATH = 'https://gas-sis.xgitcorp.com/';

// USERS API
export const LOGIN_ENDPOINT = BASE_ENDPOINT + LOGIN;
export const REGISTER_ENDPOINT = BASE_ENDPOINT + REGISTER;
export const GET_USERS_BY_BRANCH_ENDPOINT = BASE_ENDPOINT + USERS_ENDPOINT;
export const DEACTIVATE_USER_ENDPOINT = BASE_ENDPOINT + DEACTIVATE_USER;
export const GET_USER_BY_ID_ENDPOINT = BASE_ENDPOINT + PER_USER_ENDPOINT;
export const USER_CHANGE_PASSWORD_ENDPOINT = BASE_ENDPOINT + CHANGE_PASSWORD;

// ACCESS LEVEL API
export const ACCESS_LEVEL_ENDPOINT = BASE_ENDPOINT + ACCESS_LEVEL;

// CUSTOMERS API
export const CUSTOMERS_ENDPOINT = BASE_ENDPOINT + CUSTOMERS;

// CATEGORIES API
export const CATEGORIES_ENDPOINT = BASE_ENDPOINT + CATEGORIES;

// PRODUCTS API
export const PRODUCTS_ENDPOINT = BASE_ENDPOINT + PRODUCTS;
export const ADD_PRODUCT_ENDPOINT = BASE_ENDPOINT + ADD_PRODUCT;
export const UPDATE_PRODUCT_ENDPOINT = BASE_ENDPOINT + UPDATE_PRODUCT;
export const DELETE_PRODUCT_ENDPOINT = BASE_ENDPOINT + DELETE_PRODUCT;

// PRICE API
export const ADD_PRICE_ENDPOINT = BASE_ENDPOINT + ADD_PRICE;
export const UPDATE_PRICE_ENDPOINT = BASE_ENDPOINT + UPDATE_PRICE;
export const GET_PRICE_ENDPOINT = BASE_ENDPOINT + GET_PRICE;

// BRANCHES API
export const BRANCHES_ENDPOINT = BASE_ENDPOINT + BRANCHES;

// TRANSACTIONS API
export const SAVE_ORDER_ENDPOINT = BASE_ENDPOINT + SAVE_ORDER;
export const GET_ORDER_ENDPOINT = BASE_ENDPOINT + GET_ORDER;
export const POST_ORDER_ENDPOINT = BASE_ENDPOINT + POST_ORDER;

// REPORTS API
export const GET_SALES_HISTORY_ENDPOINT = BASE_ENDPOINT + GET_SALES_HISTORY;
export const GET_DAILY_STOCKS_REPORT_ENDPOINT =
  BASE_ENDPOINT + GET_DAILY_STOCKS_REPORT;
export const GET_CHECKER_HISTORY_ENDPOINT =
  BASE_ENDPOINT + CHECKER_HISTORY_REPORT;
export const GET_CUSTOMER_HISTORY_ENDPOINT =
  BASE_ENDPOINT + CUSTOMER_HISTORY_REPORT;

// INVENTORY API
export const GET_RUNNING_INVENTORY_ENDPOINT = BASE_ENDPOINT + RUNNING_INVENTORY;
export const GET_INCOMING_HISTORY_ENDPOINT = BASE_ENDPOINT + INCOMING_HISTORY;
export const POST_BULLET_INCOMING_ENDPOINT = BASE_ENDPOINT + BULLET_INCOMING;

// OUTLET API
export const SAVE_OUTLET_ORDER_ENDPOINT = BASE_ENDPOINT + SAVE_OUTLET_ORDER;
export const GET_OUTLET_ORDER_ENDPOINT = BASE_ENDPOINT + GET_OUTLET_ORDER;
export const POST_OUTLET_ORDER_ENDPOINT = BASE_ENDPOINT + POST_OUTLET_ORDER;
export const GET_OUTLET_LIST_ENDPOINT = BASE_ENDPOINT + GET_OUTLET_LIST;
export const GET_EXPENSE_CATEGORY_ENDPOINT =
  BASE_ENDPOINT + GET_EXPENSE_CATEGORY;
export const POST_EXPENSE_REQUEST_ENDPOINT =
  BASE_ENDPOINT + POST_EXPENSE_REQUEST;
export const GET_EXPENSE_LIST_ENDPOINT = BASE_ENDPOINT + GET_EXPENSE_LIST;
export const POST_RETURN_EXPENSE_ENDPOINT = BASE_ENDPOINT + POST_RETURN_EXPENSE;

// TIMEKEEPING API
export const TIME_IN_OUT_ENDPOINT = BASE_ENDPOINT + TIME_IN_OUT;

// END OF DAY API
export const PROCESS_END_OF_DAY_ENDPOINT = BASE_ENDPOINT + PROCESS_EOD;
export const POST_END_OF_DAY_ENDPOINT = BASE_ENDPOINT + POST_EOD;
export const POST_TENDER_DECLARATION_ENDPOINT =
  BASE_ENDPOINT + POST_TENDER_DECLARATION;

// SOLID ASSETS API
export const GET_SOLID_ASSETS_PRODUCTS_ENDPOINT =
  BASE_ENDPOINT + GET_SOLID_ASSET_PRODUCTS;
export const POST_SOLID_ASSETS_TRANSACTION_ENDPOINT =
  BASE_ENDPOINT + POST_SOLID_ASSET_TRANSACTION;

// API RESPONSE
export const API_CALL_ERROR = 'api_call_error';
export const API_BOOLEAN_TRUE = '1';

// Failed returns - TOKEN
const INVALID_TOKEN = 'invalid_token';
const EXPIRED_TOKEN = 'expired_token';
const NO_TOKEN = 'no_token';

// Failed returns - LOGIN
const WRONG_CREDENTIALS = 'wrong_credentials';

// Failed returns - USERS
const USER_EXISTS = 'user_exists';
const USER_DOES_NOT_EXISTS = 'user_does_not_exist';
const NO_USER_FOUND = 'no_user_found';

// Failed returns - RECORDS
const RECORD_EXISTS = 'record_already_exists';
const NO_RECORD_FOUND = 'no_record_found';

// Failed return - TRANSACTION
const NO_TRANSACTION_FOUND = 'no_transaction_found';

// Failed return - EXPENSE
const VOUCHER_ALREADY_USED = 'voucher_already_used';

// Failed returns - GENERAL
const SOMETHING_WENT_WRONG = 'something_went_wrong';
const INVALID_REQUEST = 'invalid_request';

// Passed returns - USERS
const USER_SUCCESS_ADD = 'user_success_add';
const USER_SUCCESS_DEACTIVATE = 'user_deactivated';
const USER_SUCCESS_UPDATE = 'user_updated';
const USER_PASSWORD_CHANGED = 'password_changed';

// PASSED returns - RECORDS
const RECORD_SUCCESS_ADD = 'add_record_success';
const RECORD_SUCCESS_UPDATE = 'update_record_success';
const EXPENSE_ADD_SUCCESS = 'expense_add_success';

// Success response - TRANSACTION
const POSTED_SUCCESS = 'transaction_posted_success';

export const getAPIResponseMessage = (apiReturn) => {
  switch (apiReturn) {
    case INVALID_TOKEN:
      return 'Invalid Authorization token.';
    case EXPIRED_TOKEN:
      return 'Expired Authorization token.';
    case NO_TOKEN:
      return 'No provided token.';
    case WRONG_CREDENTIALS:
      return 'Wrong credentials.';
    case USER_EXISTS:
      return 'User already exists.';
    case USER_DOES_NOT_EXISTS:
      return 'User does not exists.';
    case NO_USER_FOUND:
      return 'No user found.';
    case SOMETHING_WENT_WRONG:
      return 'Something went wrong.';
    case INVALID_REQUEST:
      return 'Invalid request. Please fill up all required field.';
    case NO_TRANSACTION_FOUND:
      return 'No Transaction found.';
    case RECORD_EXISTS:
      return 'Record already exists.';
    case NO_RECORD_FOUND:
      return 'No record found.';
    case 'product_already_exist':
      return 'Product already exists.';
    case VOUCHER_ALREADY_USED:
      return 'Voucher is already used.';

    // HAPPY PATHS
    case USER_SUCCESS_ADD:
      return 'User added.';
    case USER_SUCCESS_DEACTIVATE:
      return 'User deactivated.';
    case USER_SUCCESS_UPDATE:
      return 'User updated.';
    case USER_PASSWORD_CHANGED:
      return 'Password updated.';
    case (RECORD_SUCCESS_ADD, EXPENSE_ADD_SUCCESS):
      return 'Record added.';
    case RECORD_SUCCESS_UPDATE:
      return 'Record updated.';
    default:
      return 'Action is not successful.';
  }
};

// APP WIDE DEFINITIONS

// NOTIFICATION TYPE DEFINITIONS
export const SUCCESS_NOTIFICATION_TYPE = 'success';
export const INFO_NOTIFICATION_TYPE = 'info';
export const WARNING_NOTIFICATION_TYPE = 'warning';
export const ERROR_NOTIFICATION_TYPE = 'error';
