import axios from 'axios';
import {
  ADD_PRODUCT_ENDPOINT,
  API_CALL_ERROR,
  DELETE_PRODUCT_ENDPOINT,
  PRODUCTS_ENDPOINT,
  UPDATE_PRODUCT_ENDPOINT,
} from './_ConstantsAPI';

export async function getProducts() {
  try {
    const { data } = await axios.get(PRODUCTS_ENDPOINT, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getProductsByCategoryId(categoryId) {
  try {
    const { data } = await axios.get(PRODUCTS_ENDPOINT, {
      params: {
        categoryId: categoryId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getProductsBySearch(search) {
  try {
    const { data } = await axios.get(PRODUCTS_ENDPOINT, {
      params: {
        search: search,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function addProduct(payload) {
  try {
    const { data } = await axios.post(ADD_PRODUCT_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function updateProduct(payload) {
  try {
    const { data } = await axios.post(UPDATE_PRODUCT_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function deleteProduct(payload) {
  try {
    const { data } = await axios.post(DELETE_PRODUCT_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
