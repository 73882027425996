export default {
  isSuccessfl: '',
  orderNumber: '',
  orderList: [
    {
      id: '',
      productName: '',
      productDesc: '',
      productQty: '',
      pricePerkg: '',
      productPriceperkg: '',
    },
  ],
};
