import { Button, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

export function getProductsTable(handleAction) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
      title: 'Product Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleAction(record.id, 'edit')}
            icon={<AiFillEdit size={20} />}
            className="mx-2"
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure to delete ${record.name}?`}
            onConfirm={() => handleAction(record.id, 'delete')}
          >
            <Button type="primary" danger icon={<AiFillDelete size={20} />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
}
