import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { getProducts } from '../../utils/api/ProductsAPI';
import {
  ERROR_NOTIFICATION_TYPE,
  SUCCESS_NOTIFICATION_TYPE,
  getAPIResponseMessage,
} from '../../utils/api/_ConstantsAPI';
import {
  getIncomingHistory,
  getRunningInventory,
  postBulletIncoming,
} from '../../utils/api/InventoryAPI';
import { UserContext } from '../../utils/context/UserContext';
import { IoIosRefresh } from 'react-icons/io';
import { getIncomingHistoryTable } from '../../utils/tableData/Inventory/IncomingHistoryTable';
import RunningInventoryDefaultType from '../../utils/types/RunningInventoryDefaultType';
import { FormInput } from '../../components';
import BulletTankIncomingDefaultType from '../../utils/types/BulletTankIncomingDefaultType';
import { GlobalContext } from '../../App';

export default function BulletTankInventory() {
  const { profile, formItemLayout } = useContext(UserContext);
  const { openNotification } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [isIncomingModalOpen, setIsIncomingModalOpen] = useState(false);
  const [incomingState, setIncomingState] = useState(
    BulletTankIncomingDefaultType
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForIncomingHist, setIsLoadingForIncomingHist] =
    useState(false);
  const [isLoadingForIncomingRequest, setIsLoadingForIncomingRequest] =
    useState(false);

  const [selectedBulletTankId, setSelectedBulletTankId] = useState(null);

  const [bulletTankList, setBulletTankList] = useState([]);
  const [incomingHistoryList, setIncomingHistoryList] = useState([]);

  const [runningInventoryOfSelectedTank, setRunningInventoryOfSelectedTank] =
    useState(RunningInventoryDefaultType);

  const tableContents = incomingHistoryList.map((data, index) => ({
    ...data,
    key: index,
  }));

  const getBulletTanks = async () => {
    setIsLoading(true);
    const res = await getProducts();
    if (res.isSuccessful) {
      const tanks = res.data.filter((tank) => tank.isTank === '1');
      setBulletTankList(tanks);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Bullet tank inventory',
        getAPIResponseMessage(res.data)
      );
    }
    setIsLoading(false);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() / 60; // Convert to hours
    const timezoneOffsetHours = timezoneOffset + 8; // Adjust for Philippines time zone (+8 hours)

    currentDate.setHours(currentDate.getHours() + timezoneOffsetHours);

    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const getRunningInventoryOfSelectedTank = async () => {
    setIsLoading(true);
    const res = await getRunningInventory(selectedBulletTankId);
    if (res.isSuccess) {
      setRunningInventoryOfSelectedTank(res);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Bullet tank inventory',
        'An error occured.'
      );
    }
    setIsLoading(false);
  };

  const getIncomingHistoryForSelectedTank = async () => {
    setIsLoadingForIncomingHist(true);
    const res = await getIncomingHistory(
      profile.branchCode,
      selectedBulletTankId,
      '2023-01-01',
      getCurrentDate()
    );
    if (res.isSuccess) {
      setIncomingHistoryList(res.incomingHistory);
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Bullet tank inventory',
        'An error occured.'
      );
    }
    setIsLoadingForIncomingHist(false);
  };

  const handleIncomingModalSave = async () => {
    setIsLoadingForIncomingRequest(true);
    const res = await postBulletIncoming(incomingState);
    if (res.isSuccess) {
      setIsIncomingModalOpen(false);
      getRunningInventoryOfSelectedTank();
      getIncomingHistoryForSelectedTank();
      setIncomingState(BulletTankIncomingDefaultType);
      openNotification(
        SUCCESS_NOTIFICATION_TYPE,
        'Bullet tank inventory',
        res.message
      );
    } else {
      openNotification(
        ERROR_NOTIFICATION_TYPE,
        'Bullet tank inventory',
        res.message
      );
    }
    setIsLoadingForIncomingRequest(false);
  };

  useEffect(() => {
    getBulletTanks();
  }, []);

  return (
    <Content>
      <Typography.Title level={3}>Bullet Tank Inventory</Typography.Title>
      <Row>
        <Space.Compact>
          <Select
            style={{ width: '20vw' }}
            placeholder="Please select tank"
            value={selectedBulletTankId}
            defaultActiveFirstOption
            onChange={(e) => {
              setSelectedBulletTankId(e);
            }}
            disabled={isLoading}
            options={bulletTankList.map((option) => ({
              label: option.description,
              value: option.id,
            }))}
          />
          <Button
            type="primary"
            loading={isLoading}
            disabled={selectedBulletTankId === ''}
            onClick={() => {
              getRunningInventoryOfSelectedTank();
              getIncomingHistoryForSelectedTank();
            }}
          >
            Process
          </Button>
        </Space.Compact>
      </Row>
      <Row gutter={16} className="m-4">
        <Col xs={24} sm={12}>
          <Card
            loading={isLoading || selectedBulletTankId === ''}
            className="mt-2"
            hoverable
            title="Inventory Details"
            extra={
              <Button
                type="primary"
                disabled={selectedBulletTankId === ''}
                loading={isLoading}
                onClick={() => {
                  setIncomingState({
                    ...incomingState,
                    incomeItemCode: selectedBulletTankId,
                    updatedBy: profile.id,
                    incomeBranch: profile.branchCode,
                  });
                  setIsIncomingModalOpen(true);
                }}
              >
                Incoming
              </Button>
            }
          >
            {runningInventoryOfSelectedTank.isSuccess !== false && (
              <Descriptions
                bordered
                size="small"
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Product name">
                  {runningInventoryOfSelectedTank.Stock[0].productName}
                </Descriptions.Item>
                <Descriptions.Item label="Running stock">
                  {runningInventoryOfSelectedTank.Stock[0].stockQty}
                </Descriptions.Item>
                <Descriptions.Item label="Total sales">
                  {runningInventoryOfSelectedTank.stockSales[0].totalSales}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card
            loading={isLoading}
            className="mt-2"
            hoverable
            title="Incoming History"
            extra={
              <Button
                type="primary"
                size="large"
                disabled={selectedBulletTankId === ''}
                icon={<IoIosRefresh size={24} />}
                loading={isLoading}
                onClick={() => getIncomingHistoryForSelectedTank()}
              />
            }
          >
            <Table
              columns={getIncomingHistoryTable()}
              dataSource={tableContents}
              size="small"
              bordered={true}
              scroll={{ x: true }}
              loading={isLoadingForIncomingHist}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Incoming entry"
        centered
        closable={false}
        maskClosable={false}
        open={isIncomingModalOpen}
        onOk={handleIncomingModalSave}
        okText={'Save'}
        confirmLoading={isLoadingForIncomingRequest}
        onCancel={() => setIsIncomingModalOpen(false)}
      >
        <Form
          form={form}
          name="incoming_form"
          {...formItemLayout}
          initialValues={incomingState}
        >
          <FormInput
            type="quantity"
            name="incomeQty"
            placeholder="Please enter incoming quantity"
            label="Incoming QTY"
            autoFocus={true}
            value={incomingState.incomeQty}
            onChange={(value) => {
              setIncomingState({ ...incomingState, incomeQty: value });
            }}
            required={true}
          />
        </Form>
      </Modal>
    </Content>
  );
}
