export default {
  thousands: '0',
  fiveHundreds: '0',
  twoHundreds: '0',
  oneHundreds: '0',
  fifties: '0',
  twenties: '0',
  tens: '0',
  fives: '0',
  peso: '0',
  twentyFiveCents: '0',
  fiveCents: '0',
  oneCent: '0',
};
